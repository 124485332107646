import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  state: {
    isLoading: false,
    mappingData: {
      organisationTypes: [],
      organisationPartTypes: [],
      selectedSenders: [],
      selectedReceivers: [],
      selectedDataSubjects: [],
      newDataCategories: [],
      newDataCategoryTypes: [],
      industryName: "",
      isChanged: false,

      dataExchanges: {
        senders: [],
        receivers: [],
        data_subjects: [],
      },
      dataExchangesToSave: [],
      organisations: {
        senders: [],
        receivers: [],
        data_subjects: [],
      },
      organisationParts: {
        senders: [],
        receivers: [],
        data_subjects: [],
      },
    },
  },
  getters,
  actions,
  mutations,
};
