<template>
  <div class="dgd-items-list">
    <div :class="['dgd-link-available dgd-item-wrapper', getStatus(block.css_status)]">
      <div :class="['dgd-ball-line', `dgd-type-${index}`]" @click="redirectToAuditDashboard">
        <div class="dgd-ball dgdb-realestate"></div>
        <div class="dgd-line">
          <span>
            {{ block.name }}
          </span>
          <span class="dgd-main-link">
            Continue
          </span>
          <a
            :href="auditDashboardLink"
            v-bind:class="['dgd-edit-link dgd-link-tooltip', { 'dgd-check-link': block.customer_answer_present }]"
            v-tooltip="tooltip"
          ></a>
          <div v-if="false" class="progress-left" :style="`width:calc(100% - ${block.css_status}%)`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vTooltip from "v-tooltip";

Vue.use(vTooltip);

import "./AuditFlow.scss";
export default {
  name: "dg-audit-flow",
  props: {
    block: {
      type: Object,
    },
    index: {
      type: Number,
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
  computed: {
    auditDashboardLink() {
      const { audit_dashboard_link } = this.block;
      if (!audit_dashboard_link || this.hasJsVoid(audit_dashboard_link)) return "javascript:void(0);";
      return `/redirect${audit_dashboard_link}`;
    },
  },
  methods: {
    getStatus(status) {
      return `status-${status}`;
    },
    hasJsVoid(link) {
      return link.includes("javascript:void(0);");
    },
    redirectToAuditDashboard() {
      if (this.hasJsVoid(this.auditDashboardLink)) return;
      window.location.href = this.auditDashboardLink;
    },
  },
};
</script>
