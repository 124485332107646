export default [
  {
    path: "c/:id/profile/",
    redirect: "c/:id/profile/privacy",
    component: () => import(/* webpackChunkName: "profile" */ "./Layout.vue"),
    props: true,
    children: [
      {
        name: "Profile",
        path: ":package",
        component: () => import(/* webpackChunkName: "profile" */ "./views/Profile.vue"),
        meta: {
          requiresAuth: true,
          title: "Profile",
          featureName: "Profile",
          translationKey: "profile",
        },
      },
    ],
  },
];
