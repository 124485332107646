export default {
  START_LOADING(state) {
    state.isLoading = true;
  },
  STOP_LOADING(state) {
    state.isLoading = false;
  },
  SET_MAPPING_INDUSTRY_NAME(state, industryName) {
    state.mappingData.industryName = industryName;
  },
  SET_MAPPING_CHANGED_STATE(state, payload) {
    state.mappingData.isChanged = payload;
  },
  SET_MAPPING_ORGANISATION_TYPES(state, organisationTypes) {
    state.mappingData.organisationTypes = organisationTypes;
  },
  SET_MAPPING_ORGANISATION_PART_TYPES(state, organisationPartTypes) {
    state.mappingData.organisationPartTypes = organisationPartTypes;
  },
  SET_MAPPING_NEW_DATA_CATEGORY_TYPES(state, newDataCategoryTypes) {
    state.mappingData.newDataCategoryTypes = newDataCategoryTypes;
  },
  SET_MAPPING_NEW_DATA_CATEGORIES(state, newDataCategories) {
    state.mappingData.newDataCategories = newDataCategories;
  },
  SET_MAPPING_ORGANISATIONS(state, { data, entityKeyName }) {
    state.mappingData.organisations[entityKeyName] = data;
  },
  SET_MAPPING_ORGANISATION_PARTS(state, { data, entityKeyName }) {
    state.mappingData.organisationParts[entityKeyName] = data;
  },
  SET_SELECTED_SENDERS(state, selectedSenders) {
    state.mappingData.selectedSenders = selectedSenders;
  },
  SET_SELECTED_RECEIVERS(state, selectedReceivers) {
    state.mappingData.selectedReceivers = selectedReceivers;
  },
  SET_SELECTED_DATA_SUBJECTS(state, selectedDataSubjects) {
    state.mappingData.selectedDataSubjects = selectedDataSubjects;
  },
  SET_DATA_EXCHANGES_TO_SAVE(state, dataExchanges) {
    state.mappingData.dataExchangesToSave = dataExchanges;
  },
  SET_MAPPING_DATA_EXCHANGES(state, { data, entityKeyName }) {
    state.mappingData.dataExchanges[entityKeyName] = data;
  },
  ADD_DATA_CATEGORY_TO_DATA_SUBJECT(state, { dataSubject, dataCategories }) {
    const selectedDataSubject = state.mappingData.selectedDataSubjects.find(
      s => s.id === dataSubject.id && s.entity_type === dataSubject.entity_type
    );
    if (selectedDataSubject) {
      selectedDataSubject.data_categories = state.mappingData.newDataCategories.filter(c =>
        dataCategories.includes(c.name)
      );
      const dataCategoriesToSave = selectedDataSubject.data_categories.map(c => {
        return { id: c.id };
      });

      state.mappingData.dataExchangesToSave.forEach(de => {
        de.question_groups.forEach(q => {
          q.data_exchanges.forEach(d => {
            if (d.data_subject.id === dataSubject.id && d.data_subject.entity_type === dataSubject.entity_type) {
              d.data_categories = dataCategoriesToSave;
              if (d.id) {
                d.action = "update";
              }
            }
          });
        });
      });
    }
  },
  REMOVE_ENTITY_FROM_DATA_EXCHANGES(state, { auditProcessId, questionGroupId, entity, entityKeyName }) {
    const auditProcess = state.mappingData.dataExchangesToSave.find(a => a.id === auditProcessId);
    if (auditProcess) {
      let questionGroup = auditProcess.question_groups.find(q => q.id === questionGroupId);
      if (questionGroup) {
        if (entityKeyName === "data_subjects") {
          const index = questionGroup.data_exchanges.findIndex(
            e => e.data_subject.id === entity.id && e.data_subject.entity_type === entity.entity_type
          );
          if (index >= 0) {
            let dataExchange = questionGroup.data_exchanges[index];
            if (dataExchange.id) {
              dataExchange.action = "delete";
            } else {
              questionGroup.data_exchanges.splice(index, 1);
            }
          }
        } else {
          questionGroup[entityKeyName] = questionGroup[entityKeyName].filter(
            e => (e.id === entity.id && e.entity_type === entity.entity_type) === false
          );
        }
      }
    }
  },
  ADD_ENTITY_TO_DATA_EXCHANGES(state, { auditProcessId, questionGroupId, entity, entityKeyName }) {
    let questionGroup = state.mappingData.dataExchangesToSave
      .find(d => d.id === auditProcessId)
      .question_groups.find(q => q.id === questionGroupId);
    let entityToAdd = { id: entity.id, entity_type: entity.entity_type };
    if (entityKeyName === "data_subjects") {
      questionGroup.data_exchanges.push({
        data_subject: entityToAdd,
        data_categories: (entity.data_categories || []).map(c => {
          return { id: c.id };
        }),
        action: "create",
      });
    } else {
      questionGroup[entityKeyName].push(entityToAdd);
    }
  },
  REMOVE_SELECTED_ENTITIES(state, { entityKeyName, selectedEntities, removedEntities }) {
    // Loop through every data exchange to find the question groups where the removed entities are mapped
    state.mappingData.dataExchangesToSave
      .filter(a => a.question_groups.length > 0)
      .forEach(auditProcess => {
        auditProcess.question_groups.forEach(questionGroup => {
          if (entityKeyName === "data_subjects") {
            // Find data exchanges which contain the removed data subjects and mark action as delete.
            questionGroup.data_exchanges
              .filter(
                d =>
                  removedEntities.filter(
                    e => e.id === d.data_subject.id && e.entity_type === d.data_subject.entity_type
                  ).length
              )
              .forEach(de => {
                de.action = "delete";
              });
          } else {
            // Find question groups which had the removed entities selected and remove those entities from each question group.
            if (questionGroup[entityKeyName].filter(e => removedEntities.filter(r => r.id === e.id).length)) {
              questionGroup[entityKeyName] = questionGroup[entityKeyName].filter(
                e => removedEntities.filter(r => r.id === e.id).length === 0
              );
            }
          }
        });
      });
  },
};
