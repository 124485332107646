<template>
  <div v-if="dpo" class="dg-dpointro">
    <div class="dg-dpointro__img" :style="backgroundStyles(dpo.image)">
      &nbsp;
    </div>

    <div class="dg-dpointro__content">
      <div class="dg-dpointro__title">{{ title }}</div>
      <div class="dg-dpointro__desc">
        <slot name="desc"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import "./Dpointro.scss";

export default {
  name: "dg-dpointro",
  props: {
    dpo: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  methods: {
    backgroundStyles(image) {
      return {
        // any other styles you might need to add on as an example
        "background-image": `url(data:image/gif;base64,${image})`,
      };
    },
  },
};
</script>
