export default {
  state: {
    sidebarMenuVisibility: false,
    mainNavigation: [],
    sidebarNavigation: [],
  },
  getters: {},
  actions: {
    toggleSidebarMenu({ commit }) {
      commit("TOGGLE_SIDEBAR");
    },
  },
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.sidebarMenuVisibility = !state.sidebarMenuVisibility;
    },
    SET_MENU(state, menu) {
      state.sidebarNavigation = menu;
    },
  },
};
