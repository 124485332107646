var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'hamburger hamburger--spin',
    {
      'is-active': _vm.isActive,
    },
  ],attrs:{"type":"button"},on:{"click":_vm.handleClick}},[_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})])
}]

export { render, staticRenderFns }