import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  state: {
    customerActivity: null,
    auditArea: null,
    companyNo: null,
    uploadedDocs: {},
    uploadsLoading: true,
  },

  mutations,
  getters,
  actions,
};
