<template>
  <div :class="[`dg-form-file-upload`, { errorInput: onError }, ...classes]">
    <label v-if="label" :for="_name">
      {{ label }}
      <span class="required" v-if="required">*</span>
    </label>
    <input
      type="file"
      :id="_name"
      :name="_name"
      v-bind:value="value"
      class="dg-input"
      :class="{ error: onError }"
      :accept="accept"
      :required="required"
      @change="onChange"
    />

    <p v-if="onError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
// assets
import "./FileUpload.scss";

export default {
  name: "DgFileUpload",
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    accepts: {
      type: String,
      default: "image/png, image/jpeg",
    },
    validator: {
      type: Function,
      default(input) {
        return !!input;
      },
    },
    condition: {
      type: Object,
      default() {
        return {
          action: "none",
          target: "",
          condition: "",
          value: "",
        };
      },
    },
  },
  data() {
    return {
      internalFiles: this.files,
      value: "",
      classes: [],
      onError: false,
    };
  },
  methods: {
    onChange(event) {
      this.$emit("change", event.target.files, event);
    },
  },
  computed: {
    _name: function() {
      if (this.name !== "") {
        return this.name;
      } else {
        return this.label
          .toLowerCase()
          .replace(" ", "-")
          .replace("?", "-");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-form-input.error {
  input {
    border-color: #c63040;
  }
}
</style>
