import Vue from "vue";
import Vuex from "vuex";
import { Common, Dsr, Mcp, Cpe, Dd, DGSuite, PPM, ToDo } from "@/engines";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loggedin: Boolean,
  },
  getters: {
    isLoggedIn(state) {
      return state.loggedin;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    ...Common.modules,
    ...Dsr.modules,
    ...Mcp.modules,
    ...Cpe.modules,
    ...Dd.modules,
    ...DGSuite.modules,
    ...PPM.modules,
    ...ToDo.modules,
  },
});

export default store;
