export default [
  {
    path: "c/:id/",
    component: () => import(/* webpackChunkName: "customer-dashboard" */ "./Layout.vue"),
    props: true,
    children: [
      {
        name: "Dashboard",
        path: "/",
        component: () => import(/* webpackChunkName: "customer-dashboard" */ "./views/Dashboard.vue"),
        meta: {
          title: "DataGuard",
          translationKey: "customer_dashboard",
          requiresAuth: true,
        },
      },
    ],
  },
];
