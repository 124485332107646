import { generateNavItems } from "@/utils";
import * as types from "./types";
import axios from "axios";

const PRIVACY_DOSSIER_ID = "34996392403";
const PRIVACY_DOSSIER_ID_UK = "46027205150";
const MAGAZINE_ID = "29905861274";
const MAGAZINE_ID_UK = "35848164598";
const MAGAZINE_CATEGORY_LIST = [
  "51310088073",
  "34947767565",
  "51307264670",
  "51307540769",
  "51307540780",
  "42842754207",
];
const MAGAZINE_CATEGORY_LIST_UK = ["38294303202", "48149146925", "48482553383", "48149156780", "41088270174"];
const featureMap = [
  { featureName: "ToDos", linkKey: "todos" },
  { featureName: "Documentation Dashboard", linkKey: "documentation" },
  { featureName: "DSR", linkKey: "dsr" },
  { featureName: "News Portal", linkKey: "news" },
  { featureName: "PPM", linkKey: "ppm" },
  { featureName: "DGSuite", linkKey: "dataguard_suite" },
  { featureName: "NPS", linkKey: "nps" },
  { featureName: "Profile", linkKey: "profile" },
];

export default {
  state: {
    companyData: null,
    userData: null,
    hubspotDetails: null,
    fetchingAllData: true,
    fetchedAllData: false,
    externalLinks: [],
    fetchingExternalLinks: false,
  },
  getters: {
    userData(state) {
      return state.userData;
    },
    companyData(state) {
      return state.companyData;
    },
    userPermissions(state) {
      return state.companyData?.permissions;
    },
    isFeatureEnabled: state => featureKey => {
      return (state.companyData?.active_features || []).includes(featureKey);
    },
    activeFeatures: state => {
      return state.companyData?.active_features;
    },
    hubspotDetails(state) {
      if (state.companyData) {
        const { jurisdiction_title = "" } = state.companyData;
        if (jurisdiction_title === "UK") {
          return {
            privacyDossierTopicId: PRIVACY_DOSSIER_ID_UK,
            magazineTopicId: MAGAZINE_ID_UK,
            categoryList: MAGAZINE_CATEGORY_LIST_UK,
            tKey: "uk_categories",
          };
        }
      }
      return {
        privacyDossierTopicId: PRIVACY_DOSSIER_ID,
        magazineTopicId: MAGAZINE_ID,
        categoryList: MAGAZINE_CATEGORY_LIST,
        tKey: "categories",
      };
    },
    isCustomerFeatureActivated: state => featureName => {
      if (!(state.companyData && state.companyData.customer_features.length)) {
        return false;
      }

      return state.companyData.customer_features.find(feature => feature.feature_name === featureName);
    },
    featureLinkKey(state) {
      return (currentRoute = "", featureType) => {
        const featureLinkDetails = featureMap.find(item => {
          return item.featureName === currentRoute;
        });
        if (!featureLinkDetails) {
          throw new Error("You need to update the feature map object");
        }
        const { linkKey } = featureLinkDetails;
        return state.externalLinks.find(({ link_key, link_type }) => {
          return link_key === linkKey && link_type === featureType;
        });
      };
    },
  },
  actions: {
    async getUserAndCompanyData({ commit, dispatch, state }, { id, lang }) {
      if (state.fetchedAllData) return;
      try {
        // Fetch User Data
        const userData = await axios.get("/api1/v2/users/1", { params: { locale: lang } });
        commit(types.USER_DATA_SET, userData.data.data);
        await dispatch("fetchExternalLinks");

        // Fetch Customer Data
        const response = await axios.get(`/api1/v2/customers/${id}`, { params: { locale: lang } });
        commit(types.COMPANY_DATA_SET, { companyData: response.data.data, routeParams: { id, lang } });

        commit("SET_FETCHED_ALL_DATA");
      } catch (err) {
        // This error will happen when token is invalid
        /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
        console.error(err);
      }
    },
    async fetchExternalLinks({ commit, state }) {
      if (state.userData["role"] === "customer") {
        const { data } = await axios.get(`/api1/v2/external_links`);
        commit("SET_EXTERNAL_LINKS", data.data);
      }
    },
  },
  mutations: {
    [types.USER_DATA_SET](state, userData) {
      state.userData = userData;
    },
    SET_FETCHED_ALL_DATA(state) {
      state.fetchingAllData = false;
      state.fetchedAllData = true;
    },
    [types.COMPANY_DATA_SET](state, { companyData, routeParams }) {
      const { lang, id } = routeParams;
      const navItems = generateNavItems(companyData.navigation_link, id, lang);
      companyData.navigation_link = navItems;
      state.companyData = companyData;
    },
    SET_EXTERNAL_LINKS(state, data = []) {
      state.externalLinks = data;
    },
  },
};
