<template>
  <div>
    <div
      :class="['dg-dragndrop', { 'dg-dragndrop--active': files.length > 0 }]"
      v-bind:style="{ 'background-color': color }"
      @dragover.prevent
      @drop.prevent
    >
      <div @dragleave="fileDragOut" @dragover="fileDragIn" @drop="handleFileDrop">
        <div class="dg-dragndrop__wrapper"><slot name="container"> </slot></div>

        <div class="dg-dragndrop__inputHandler">
          <input type="file" ref="fileInput" name="file-input" multiple @change="handleFileInput" />
        </div>
        <ul class="dg-dragndrop__files">
          <li class="dg-dragndrop__file" v-for="(file, index) in files" v-bind:key="index">
            <div class="dg-dragndrop__file-text">{{ file.name }} ({{ file.size }} b)</div>
            <button @click="removeFile(index)" title="Remove" class="dg-dragndrop__remove" type="button">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0Z"
                  fill="white"
                />
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.5 10.1L10.1 11.5L8 9.4L5.9 11.5L4.5 10.1L6.6 8L4.5 5.9L5.9 4.5L8 6.6L10.1 4.5L11.5 5.9L9.4 8L11.5 10.1Z"
                  fill="#C63040"
                />
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <p class="dg-dragndrop__text" v-if="hintText">{{ hintText }}</p>
  </div>
</template>
<script>
export default {
  name: "DgDragnDrop",
  props: {
    hintText: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: null,
    },
    fileTypes: {
      type: Array,
      default() {
        return ["application/pdf", "image/jpeg", "image/png"];
      },
    },
    initialFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    maxFileSize: {
      type: Number,
      default: 5242880,
    },
  },
  data() {
    return {
      files: [...this.initialFiles],
      color: "#f1f1f1",
    };
  },
  methods: {
    handleFileDrop(e) {
      this.fileDragOut();
      let droppedFiles = e.dataTransfer.files;
      this.handleFileValidation(droppedFiles);
      this.color = "#f9f9f9";
    },
    handleFileInput(e) {
      let pulledFiles = e.target.files;
      this.handleFileValidation(pulledFiles);
    },
    handleFileValidation(filesArr) {
      if (!filesArr) return;
      this.$emit("maxFilesError", false);
      this.$emit("invalidFileTypeError", false);
      if (this.files.length + filesArr.length <= this.maxFiles) {
        [...filesArr].forEach(f => {
          if (f.size > this.maxFileSize) {
            this.$emit("maxFilesError", true);
            return;
          }
          if (this.fileTypes.includes(f.type)) {
            const hasFile = this.files.find(file => file.name === f.name);
            if (!hasFile) {
              this.files.push(f);
              this.$emit("filesDropped", this.files);
            }
          } else {
            this.$emit("invalidFileTypeError", true);
          }
        });
      } else {
        this.$emit("maxFilesError", true);
      }
      this.$refs.fileInput.value = null;
    },
    removeFile(fileKey) {
      this.files.splice(fileKey, 1);
      this.$emit("fileRemoved", fileKey);
    },
    fileDragIn() {
      // alert("oof")
      // alert("color")
      this.color = "#ffffff";
    },
    fileDragOut() {
      this.color = "#f9f9f9";
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-dragndrop {
  position: relative;
  width: 100%;
  height: 150px;
  border: 1px dashed #dcdcdc;
  border-radius: 4px;

  .dg-dragndrop__wrapper {
    position: absolute;
    width: 276px;
    height: 64px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &.dg-dragndrop--active {
    .cxr-wrapper__dnd {
      height: auto;
      justify-content: center;
      align-items: center;

      .cxr-wrapper__dnd-desc {
        display: none;
      }
    }

    .dg-dragndrop__wrapper {
      top: auto;
      bottom: 24px;
      height: auto;
    }
  }

  .dg-dragndrop__inputHandler {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
    padding: 0 20px;
  }

  .dg-dragndrop__inputHandler input {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 300px;
    height: 200px;
  }

  .dg-dragndrop__files {
    padding: 20px 10px 80px 10px;

    .dg-dragndrop__file {
      list-style-type: none;
      position: relative;
      z-index: 1;
      background: #9aa7b0;
      border-radius: 4px;
      color: #fff;
      width: 60px;
      height: 50px;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      .dg-dragndrop__file-text {
        justify-content: flex-end;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-direction: column;
        width: 100%;
        font-size: 10px;
        padding: 4px;
        display: block;
        padding-top: 25px;
      }

      .dg-dragndrop__remove {
        position: absolute;
        border: none;
        background: none;
        top: -10px;
        right: -13px;
      }
    }
  }
}

.dg-dragndrop__text {
  color: #718496;
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
}
</style>
