<template>
  <div class="dg-button-group" :class="type">
    <slot />
  </div>
</template>

<script>
export default {
  name: "dg-button-group",
  props: {
    type: {
      type: String,
      default: "horizontal",
    },
  },
};
</script>
