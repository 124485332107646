import axios from "axios";
export default {
  setCustomerActivity({ commit }, customerActivity) {
    commit("SET_CUSTOMER_ACTIVITY", customerActivity);
  },
  setAuditArea({ commit }, auditArea) {
    commit("SET_AUDIT_AREA", auditArea);
  },
  loadInitialData({ commit }, customerID) {
    axios.get(`/api1/v2/customers/${customerID}/audit_area_activities?return_content=false`).then(response => {
      const { data: activities } = response;
      if (activities) {
        commit(
          "SET_CUSTOMER_ACTIVITY",
          activities.filter(a => a.assigns?.find(aa => aa.publish))
        );
      }
    });
  },
  loadAuditArea({ commit }) {
    // Get all the audit Area titles
    axios.get(`/api1/v2/audit_areas?by_category=audit`).then(response => {
      commit("SET_AUDIT_AREA", response.data);
    });
  },
  loadUserDocs({ commit }, companyNo) {
    commit("SET_UPLOADS_LOADING", true);
    axios
      .get(`/api1/v2/customers/${companyNo}/customer_documents`)
      .then(response => {
        commit("SET_USER_DOCS", response.data);
        commit("SET_UPLOADS_LOADING", false);
      })
      .catch(error => {
        commit("SET_UPLOADS_LOADING", false);
        this.apiCatch(error);
      });
  },
  setUploadsLoading({ commit }, data) {
    commit("SET_UPLOADS_LOADING", Boolean(data));
  },
};
