<template>
  <div class="table--inverted">
    <div class="table-options" v-if="pagination">
      <div class="pagination_count">Showing {{ item_count }} of {{ pagination.total_count }}</div>
      <div class="pagination" v-if="pagination.total_pages > 1">
        <ul>
          <li>
            <a v-if="pagination.prev_page" @click.prevent="prev" class="pagination__num prev" href="#"> &lt;&lt; </a>
          </li>
        </ul>
        <ul>
          <li v-for="(page, index) in pagination.total_pages" v-bind:key="index">
            <a
              class="pagination__num"
              v-if="page !== pagination.current_page"
              @click.prevent="pageClick(page)"
              href="#"
              >{{ page }}</a
            >
            <span class="pagination__num active" v-else>{{ page }}</span>
          </li>
        </ul>
        <ul>
          <li>
            <a v-if="pagination.next_page" @click.prevent="next" class="pagination__num next" href="#"> &gt;&gt; </a>
          </li>
        </ul>
      </div>
    </div>
    <table class="dg-table">
      <thead v-if="items">
        <template v-if="columns">
          <th v-for="(field, index) in columns" v-bind:key="index">
            {{ field }}
          </th>
        </template>
      </thead>
      <tbody>
        <slot v-if="sloted" name="items"></slot>
        <tr v-else v-for="(item, index) in items" v-bind:key="index">
          <slot name="first-column"> </slot>
          <td v-for="(itemData, index) in item" v-bind:key="index" v-html="itemData"></td>
          <slot name="last-column"> </slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "./Table.scss";

export default {
  name: "dg-table",
  props: {
    columns: {
      type: [Array, Set],
    },
    sloted: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    hideColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object,
    },
  },
  computed: {
    item_count() {
      let high_num = (this.pagination.current_page - 1) * this.pagination.per_page + 10;
      return `${(this.pagination.current_page - 1) * this.pagination.per_page + 1} - ${
        high_num > this.pagination.total_count ? this.pagination.total_count : high_num
      }`;
    },
  },
  methods: {
    pageClick(page) {
      return this.$emit("pageClick", page);
    },
    prev() {
      return this.pageClick(this.pagination.current_page - 1);
    },
    next() {
      return this.pageClick(this.pagination.current_page + 1);
    },
  },
};
</script>
