<template>
  <div class="dg-toast" v-if="visible">
    <div class="dg-toast-overlay" v-if="showOverlay"></div>
    <transition-group name="fade" tag="ol">
      <li class="dg-toast-wrap" v-for="toast in toasts" :key="toast.id">
        <div class="dg-toast-content">
          <span class="type" :class="[toast.type]">{{ toast.title }}</span>
          <span class="content" v-html="toast.content"></span>
          <div class="dg-toast-buttons">
            <DgButton v-if="toast.type === 'confirm' || toast.type === 'success'" @click="onAccept(toast)">
              {{ toast.confirmButtonLabel }}
            </DgButton>
            <DgButton v-if="toast.type === 'confirm'" @click="onReject(toast)" type="secondary">
              {{ toast.rejectButtonLabel }}
            </DgButton>
          </div>
        </div>
        <i class="dgi dgi-t-remove" v-if="renderCloseIcon(toast)" @click="closeToast(toast)" />
      </li>
    </transition-group>
  </div>
</template>

<script>
import DgToast from "./index.js";
import { DgButton } from "../../basic/";

// assets
import "./Toast.scss";

export default {
  name: "DgToast",
  components: {
    DgButton,
  },
  data() {
    return {
      visible: false,
      showOverlay: false,
      toasts: [],
    };
  },
  methods: {
    onAccept(toast) {
      if (toast.onAccept) {
        toast.onAccept();
        return this.closeToast(toast);
      }
      return this.closeToast(toast);
    },
    onReject(toast) {
      if (toast.onReject) {
        toast.onReject();
        return this.closeToast(toast);
      }
      return this.closeToast(toast);
    },
    toggleToastsWrapper() {
      return (this.visible = !this.visible);
    },
    closeToast(toast) {
      const index = this.toasts.findIndex(function(indexToast) {
        return indexToast.id === toast.id;
      });
      this.toasts.splice(index, 1);

      if (toast.type === "confirm" && this.showOverlay) {
        this.showOverlay = false;
      }
      if (this.toasts.length === 0) {
        this.toggleToastsWrapper();
      }
    },
    renderCloseIcon(toast) {
      if (toast.type === "confirm") {
        return false;
      } else if (toast.closeTimeout) {
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    DgToast.EventBus.$on("show", async params => {
      if (!this.visible) {
        await this.toggleToastsWrapper();
      }

      const id = { id: String(Date.now()) + Math.floor(Math.random() * 10000) };
      const toast = Object.assign(id, params);
      this.toasts.unshift(toast);

      if (toast.closeTimeout) {
        const self = this;
        setTimeout(() => {
          self.closeToast(toast);
        }, toast.closeTimeout);
      }
      if (toast.type === "confirm") {
        this.showOverlay = true;
      }
    });
  },
};
</script>
