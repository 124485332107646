var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `dg-overlay ${_vm.extraClass}`,
    {
      absolute: _vm.position === 'absolute',
      active: _vm.visible,
    },
  ],on:{"click":_vm.handleOnClick}})
}
var staticRenderFns = []

export { render, staticRenderFns }