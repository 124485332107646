import "@/services/AxiosSetup";
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import axios from "axios";
axios.defaults.withCredentials = "include";

Vue.prototype.$http = axios;
import vToolip from "v-tooltip";
import Clipboard from "v-clipboard";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { globalMethods } from "./services";
import { Structural, Basic, Directives } from "@/dgui-customer-components";
import { i18n, Trans } from "./translations";
import BottomBar from "./engines/common/components/BottomBar";
import ActionCableVue from "actioncable-vue";
import sentinel from "@dataguard/sentinel-ds";
import "@dataguard/sentinel-ds/dist/sentinel-ds.css";

import "d3-mitch-tree/dist/css/d3-mitch-tree.min.css";
import "d3-mitch-tree/dist/css/d3-mitch-tree-theme-default.min.css";

import "@/engines/common/directives";

Vue.use(sentinel);
Vue.use(vToolip);
Vue.use(Clipboard);

function getEnvByHost() {
  let hostname = window.location.hostname;
  switch (hostname) {
    case "ui-v3.dataguard.de":
      return "production";
    case "int-ui-v3.dataguard.de":
      return "integration";
    case "stg-ui-v3.dataguard.de":
      return "staging";
    default:
      return "development";
  }
}

// Register ui-components to available in all components
Vue.use(Structural.DgHeader);
Vue.use(Structural.DgSidebar);
Vue.use(Structural.DgToast);
Vue.use(Directives.clickOutside);
Vue.use(Basic.DgOverlay);

Vue.use(ActionCableVue, {
  debug: process.env.NODE_ENV === "development",
  debugLevel: "error",
  connectImmediately: false,
});

// Mixin
Vue.mixin(globalMethods);

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.config.productionTip = false;

Vue.component(BottomBar.name, BottomBar);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    environment: getEnvByHost(),
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.25,
    trackComponents: true,
    logErrors: true,
  });
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount("#app");
