export default {
  getCustomerActivity(state) {
    return state.customerActivity;
  },
  getAuditArea(state) {
    return state.auditArea;
  },

  getCompanyNo(state) {
    return state.companyNo;
  },

  getUserDocs(state) {
    return state.uploadedDocs;
  },

  getUploadsLoading(state) {
    return state.uploadsLoading;
  },
};
