var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideList),expression:"hideList"}],staticClass:"dg-multiselect"},[_c('label',{class:[
      'multiselect-label',
      { 'label-up': _vm.showDropdown || this.selectedList.length > 0 },
      { 'label-focus': _vm.showDropdown },
    ],on:{"click":function($event){return _vm.showList()}}},[_vm._v(_vm._s(_vm.label))]),_c('ul',{ref:"multiselectList",class:['multiselect-selectedList', { 'multiselect-focus': _vm.showDropdown }],on:{"click":function($event){$event.preventDefault();return _vm.cursorOn($event)}}},[_vm._l((_vm.selectedList),function(selected,index){return _c('li',{key:index,class:[`multiselect-selected ${_vm.variant}`]},[_vm._v(" "+_vm._s(selected)+" "),_c('a',{staticClass:"multiselect-close",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeFromSelectedList(selected)}}},[_c('img',{attrs:{"src":require("../../assets/icons/circle-cross-12.svg")}})])])}),_c('li',{staticClass:"multiselect-input"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"multiselectquery",attrs:{"type":"text"},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing)return;_vm.query=$event.target.value},function($event){return _vm.queryChange($event)}],"keyup":function($event){return _vm.expand($event)},"focus":function($event){return _vm.showList()},"blur":function($event){return _vm.showList()}}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedList.length > 0),expression:"selectedList.length > 0"}],staticClass:"multiselect-clearAll",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.clearSelected.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("../../assets/icons/circle-cross-16.svg")}})])])])],2),(_vm.showDropdown)?_c('ul',{class:['multiselect-unselectedList']},[_vm._l((_vm.currentList),function(listItem,index){return _c('li',{key:index,class:[
        'multiselect-unselected',
        { 'multiselect-unselected--selected': _vm.selectedList.indexOf(listItem) !== -1 },
      ],on:{"click":function($event){return _vm.addToSelectedList(listItem)}}},[(_vm.selectionVisible && _vm.selectedList.indexOf(listItem) !== -1)?_c('img',{staticClass:"multiselect-check",attrs:{"src":require("../../assets/icons/active.svg")}}):_c('img',{staticClass:"multiselect-check",attrs:{"src":require("../../assets/icons/inactive.svg")}}),_c('span',[_vm._v(_vm._s(listItem))])])}),(_vm.none && !_vm.customOptions)?_c('li',{staticClass:"multiselect-empty"},[_vm._v("No results found")]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }