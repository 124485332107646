<template>
  <div class="dg-form-select dg-select">
    <label v-if="label" :for="_name">
      {{ label }}
      <span class="required" v-if="required">*</span>
    </label>

    <v-select
      v-model="value"
      :disabled="disabled"
      :required="required"
      :searchable="searchable"
      :multiple="multiple"
      :clearable="clearable"
      :placeholder="placeholder"
      :options="options"
      @input="onInput"
      :components="{ OpenIndicator, Deselect }"
    ></v-select>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import vSelect from "vue-select";
import OpenIndicator from "./OpenIndicator.vue";
import Close from "./Close.vue";

// assets
import "vue-select/dist/vue-select.css";
import "./Select.scss";

export default {
  name: "dg-select",
  components: { vSelect },
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
    },
    initialValue: {
      type: [String, Number, Object, Boolean, Array],
      default: "",
    },
    options: {
      type: Array,
    },
  },
  data() {
    return {
      value: this.initialValue,
      OpenIndicator,
      Deselect: Close,
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.value);
    },
  },
  computed: {
    _name: function() {
      if (this.name !== "") {
        return this.name;
      } else {
        return this.label
          .toLowerCase()
          .replace(" ", "-")
          .replace("?", "-");
      }
    },
  },
};
</script>
