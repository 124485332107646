export default [
  {
    path: "c/:id/dsr",
    component: () => import(/* webpackChunkName: "dsr-dashboard" */ "./Layout.vue"),
    props: true,
    children: [
      {
        name: "DSRDashboard",
        path: "/",
        component: () => import(/* webpackChunkName: "dsr-dashboard" */ "./views/Dashboard.vue"),
        meta: {
          title: "Data Subject Requests",
          featureName: "DSR",
          requiresAuth: true,
          sidebar: true,
          requiredPermissions: [
            { action: "view", feature: "data_subject_requests" },
            { action: "view", feature: "addon_data_subject_requests" },
          ],
        },
      },
      {
        name: "DSRDetails",
        path: "details/:dsrid",
        component: () => import(/* webpackChunkName: "dsr-details" */ "./views/Details.vue"),
        props: true,
        meta: {
          title: "Data Subject Requests Details",
          featureName: "DSR",
          requiresAuth: true,
          sidebar: true,
          requiredPermissions: [
            { action: "view", feature: "data_subject_requests" },
            { action: "view", feature: "addon_data_subject_requests" },
          ],
        },
      },
      {
        name: "DSRCreate",
        path: "new",
        component: () => import(/* webpackChunkName: "dsr-create" */ "./views/Create.vue"),
        props: true,
        meta: {
          title: "Create Data Subject Request",
          featureName: "DSR",
          requiresAuth: true,
          requiredPermissions: [
            { action: "add", feature: "data_subject_requests" },
            { action: "add", feature: "addon_data_subject_requests" },
          ],
        },
      },
      {
        name: "DSRSettings",
        path: "settings",
        component: () => import(/* webpackChunkName: "dsr-settings" */ "./views/Settings.vue"),
        props: true,
        meta: {
          title: "Data Subject Requests Settings",
          featureName: "DSR",
          requiresAuth: true,
          sidebar: true,
          requiredPermissions: [
            { action: "view", feature: "dsr_settings" },
            { action: "view", feature: "dsr_addon_settings" },
          ],
        },
      },
    ],
  },
];
