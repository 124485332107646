<template>
  <figure
    @click="onClick"
    :class="['dg-image', { 'is-rounded': isRounded }, { 'is-bordered': isBordered }, { 'object-fit': cover }]"
  >
    <img :src="url" alt="alt" :width="width" :height="height" />
  </figure>
</template>

<script>
// assets
import "./Image.scss";

export default {
  name: "DgImage",
  props: {
    isRounded: {
      type: Boolean,
      default: false,
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    link: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
