<template>
  <div :class="[`dg-pagination ${variant}`]">
    <Button class="button prev" v-show="pageNumber !== 1" @click="changePageNumber(pageNumber - 1)">
      <img src="../../assets/icons/arrow-left.svg" />
    </Button>
    <Button
      :class="['number', pageNumber == index + 1 ? 'active' : '']"
      v-for="(item, index) in totalPages"
      :key="index"
      @click="changePageNumber(index + 1)"
      >{{ index + 1 }}</Button
    >
    <Button class="button next" v-show="pageNumber !== totalPages" @click="changePageNumber(pageNumber + 1)">
      <img src="../../assets/icons/arrow-right.svg" />
    </Button>
  </div>
</template>

<script>
import "./Pagination.scss";

export default {
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
    disableRouting: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      pageNumber: Number(this.$route.query.page) || 1,
      perPage: 20,
    };
  },
  methods: {
    changePageNumber(newPageNumber) {
      this.pageNumber = newPageNumber;
      if (!this.disableRouting) {
        this.$router.push({
          path: this.$route.path,
          query: {
            page: newPageNumber,
          },
        });
      }
      this.$emit("pageNumberChanged", this.pageNumber);
    },
  },
};
</script>
