import axios from "axios";
import { i18n } from "@/translations";
const generateDeadlineExtendHistory = history => {
  return {
    ...history,
    title: i18n.t("dsr.details.extend.historyTitle"),
    description: `${i18n.t("dsr.details.extend.historyReason")}: ${history.title}\n${i18n.t(
      "dsr.details.extend.historyComment"
    )}: ${history.description}`,
  };
};

const fillPreviousHistory = historyList => {
  const historyData = [];
  historyList.forEach(history => {
    if (history.subject === "deadline") {
      historyData.push({
        history: [generateDeadlineExtendHistory(history)],
      });
    } else {
      const historyIndex = historyData.findIndex(item => item.workstep_id === history.workstep.id);
      if (historyIndex !== -1) {
        historyData[historyIndex].history.push(history);
      } else {
        historyData.push({
          workstep_id: history.workstep.id,
          title: history.workstep.title,
          history: [history],
        });
      }
    }
  });
  return historyData;
};

export default {
  state: {
    dsrDetails: {},
    dsrExemptions: {},
    fetchingDsrDetails: false,
    histories: {
      previousHistory: [],
      currentHistory: [],
    },
    fetchingHistories: false,
    dsrErr: false,
  },
  getters: {
    dsrDetails(state) {
      return state.dsrDetails;
    },
    dsrExemptions(state) {
      return state.dsrExemptions;
    },
    fetchingDsrDetails(state) {
      return state.fetchingDsrDetails;
    },
    histories(state) {
      return state.histories;
    },
    fetchingHistories(state) {
      return state.fetchingHistories;
    },
    dsrErr(state) {
      return state.dsrErr;
    },
  },
  actions: {
    async fetchDsrDetails({ commit }, { id, dsrid }) {
      commit("SET_FETCH_DSR_DETAILS", true);
      try {
        const { data } = await axios.get(`/api1/v2/customers/${id}/data_subject_requests/${dsrid}`);
        commit("SET_DSR_DETAILS", data.data);
      } catch (error) {
        commit("SET_ERR", true);
      } finally {
        commit("SET_FETCH_DSR_DETAILS", false);
      }
    },
    async fetchDsrExemptions({ commit }, { id, dsrid }) {
      const { data } = await axios.get(`/api1/v2/customers/${id}/data_subject_requests/${dsrid}/exemptions`);
      commit("SET_DSR_EXEMPTIONS", data.data);
    },
    async fetchHistories({ commit }, { id, dsrid }) {
      commit("SET_FETCH_HISTORIES", true);
      const { data } = await axios.get(`/api1/v2/customers/${id}/data_subject_requests/${dsrid}/histories`);
      commit("SET_HISTORIES", data.data);
      commit("SET_FETCH_HISTORIES", false);
    },
    fetchDetailsAndHistories({ dispatch }, { id, dsrid }) {
      dispatch("fetchDsrDetails", { id, dsrid });
      dispatch("fetchHistories", { id, dsrid });
    },
  },
  mutations: {
    SET_FETCH_DSR_DETAILS(state, flag) {
      state.fetchingDsrDetails = flag;
    },
    SET_ERR(state, flag) {
      state.dsrErr = flag;
    },
    SET_DSR_DETAILS(state, newDsrDetails) {
      const currentWorkstep = newDsrDetails.data_subject_request_workstep;
      const worksteps = newDsrDetails.data_subject_request_worksteps;
      state.dsrDetails = {
        currentWorkstep,
        worksteps,
        ...newDsrDetails,
      };
    },
    SET_DSR_EXEMPTIONS(state, data = []) {
      state.dsrExemptions = data;
    },
    SET_FETCH_HISTORIES(state, flag) {
      state.fetchingHistories = flag;
    },
    SET_HISTORIES(state, data = []) {
      const currentHistory = [];
      const tempPreviousHistory = [];

      // Sort retrieved history entries by `updated_at` in descending order.
      const orderedEntries = data.sort((current, next) => {
        return new Date(next.updated_at) - new Date(current.updated_at);
      });

      // Divide ordered entries into the current and past history.
      orderedEntries.forEach(entry => {
        if (state.dsrDetails.status !== "finished" && entry.belong_to_current_workstep) {
          if (entry.subject === "deadline") {
            currentHistory.push(generateDeadlineExtendHistory(entry));
          } else {
            currentHistory.push(entry);
          }
        }

        tempPreviousHistory.push(entry);
      });

      const previousHistory = fillPreviousHistory(tempPreviousHistory);

      state.histories = { currentHistory, previousHistory };
    },
  },
};
