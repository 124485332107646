<template>
  <li :class="[`menu-items__item menu-items__item--${menu.type}`, `menu-items__item--${menu.state} ${menu.class}`]">
    <router-link
      v-on:click.native="menu_clicked"
      class="menu-items__title"
      :to="{ name: menu.link.name, query: menu.link.query }"
    >
      <i
        v-if="menu.type === 'header' && menu.icon"
        :class="`menu-items__header-icon dgi ${menu.icon}`"
        :style="{
          backgroundColor: menu.icon_bg_color,
        }"
      />
      <div v-else>
        <img :src="menu.image" alt="" />
      </div>
      {{ menu.title }}</router-link
    >
    <ul v-if="menu.children && menu.state === 'active'" class="menu-items__sub-menu">
      <MenuItem v-for="(item, index) in menu.children" :menu="item" :key="index" />
    </ul>
  </li>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    menu: Object,
    default: () => {
      return {};
    },
  },
  methods: {
    menu_clicked(e) {
      this.$emit("linkClicked", e);
    },
  },
};
</script>
