<template>
  <button
    :class="[
      'hamburger hamburger--spin',
      {
        'is-active': isActive,
      },
    ]"
    @click="handleClick"
    type="button"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</template>

<script>
// assets
import "./HamburgerButton.scss";

export default {
  name: "DgHamburgerButton",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style></style>
