<template>
  <div class="dg-error-page">
    <h1 class="mb-30">
      {{ $t("page_not_found.text") }}
    </h1>
    <DgButton href="/redirect">
      {{ $t("page_not_found.button") }}
    </DgButton>
  </div>
</template>

<script>
import { Basic } from "@/dgui-customer-components";
export default {
  components: {
    DgButton: Basic.DgButton,
  },
};
</script>

<style lang="scss" scoped>
.dg-error-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
