import axios from "axios";

export default {
  state: {
    serverConfig: null,
  },
  getters: {
    serverConfig(state) {
      return state.serverConfig;
    },
  },
  actions: {
    async getServerConfig({ commit }) {
      const response = await axios.get("/services");
      commit("SET_SERVER_CONFIG", response.data);
    },
  },
  mutations: {
    SET_SERVER_CONFIG(state, serverConfig) {
      state.serverConfig = serverConfig;
    },
  },
};
