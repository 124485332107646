<template>
  <component
    :is="componentType"
    :type="nativeType"
    @click="onClick"
    :to="to"
    :href="href"
    :class="[
      `dg-button dg-button--${type} ${size || ''}`,
      {
        'no-hover': !hover || disabledState,
        'is-disabled': Boolean(disabledState),
        'is-loading': Boolean(loading),
        plain,
        icon,
        'only-text': Boolean(onlyText),
        'text-icon': Boolean(textIcon),
      },
    ]"
    :disabled="disabledState"
  >
    <i v-if="textIcon || icon" :class="`dgi ${finalIcon}`"></i>
    <span v-if="!icon || (!icon && textIcon)" class="dg-button__inner">
      <slot></slot>
    </span>
  </component>
</template>

<script>
// assets
import "./Button.scss";

export default {
  name: "DgButton",
  props: {
    nativeType: {
      type: String,
      default: "button",
    },
    type: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    textIcon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    onlyText: {
      type: Boolean,
      default: false,
    },
    to: [String, Object],
    href: [String, Object],
  },
  computed: {
    disabledState() {
      return this.disabled || this.loading;
    },
    finalIcon() {
      return this.textIcon || this.icon || "";
    },
    componentType() {
      if (this.to) {
        return "router-link";
      } else if (this.href) {
        return "a";
      } else {
        return "button";
      }
    },
  },
  methods: {
    onClick() {
      if (this.disabledState) {
        return;
      }

      this.$emit("click");
    },
  },
};
</script>
