<template>
  <nav
    :class="[
      'dg-sidebar-menu',
      {
        active: sidebarMenuVisibility,
      },
    ]"
  >
    <div class="dg-sidebar-menu__container">
      <ul class="menu-items">
        <MenuItem @linkClicked="linkClick" :menu="item" v-for="(item, index) in items" :key="index" />
      </ul>
    </div>
  </nav>
</template>

<script>
import MenuItem from "./MenuItem";
// assets
import "./SidebarMenu.scss";

export default {
  name: "DgSidebarMenu",
  components: {
    MenuItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    sidebarMenuVisibility: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    collapseMenu() {
      this.$emit("sidebarAction");
    },
    linkClick(e) {
      this.$emit("linkClicked", e);
    },
  },
};
</script>
