import Vue from "vue";
import VueRouter from "vue-router";
import { Trans } from "@/translations";
import { Common, Dsr, Mcp, Dd, Cpe, CxDashboard, NewsPortal, DGSuite, PPM, ToDo, Profile } from "@/engines";
import store from "../store";
import { FeatureFlag, Permission, storage } from "@/services";
import { identity } from "@/utils";

// Views component
import ErrorPage from "@/engines/common/views/ErrorPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang",
    component: {
      template: `
        <div class="dg-main-content flex-column">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
          <BottomBar />
        </div>
      `,
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      ...Common.routes,
      ...Dsr.routes,
      ...Mcp.routes,
      ...Dd.routes,
      ...Cpe.routes,
      ...CxDashboard.routes,
      ...NewsPortal.routes,
      ...DGSuite.routes,
      ...PPM.routes,
      ...ToDo.routes,
      ...Profile.routes,
      {
        name: "Maintenance",
        path: "c/:id/under-maintenance",
        component: () => import(/* webpackChunkName: "under-maintenance" */ "@/engines/common/views/Maintenance.vue"),
      },
      {
        path: "*",
        component: ErrorPage,
      },
    ],
  },
  {
    path: "/",
    redirect() {
      return Trans.defaultLanguage;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters.serverConfig) {
    await store.dispatch("getServerConfig");
  }

  identity.initialize();

  if (to.name !== "Login") {
    const { lang } = to.params;
    const id = to.params.id || to.query.id;
    id && (await store.dispatch("getUserAndCompanyData", { id, lang }));
    /**
     * Do nothing when route is no-access and set global loader flag to false
     */
    if (to.name === "no-access") {
      next();
      store.commit("SET_FETCHED_ALL_DATA");
      return;
    }
    /**
     * Redirect to no access page when user is customer and has no company assigned to the user
     */
    const { userData } = store.getters;
    if (to.name !== "no-access" && userData && userData?.role === "customer" && userData?.customers?.length === 0) {
      next({ name: "no-access", params: to.params });
      return;
    }
  }

  // This is a workaround since we reload the page after successful login and the Keycloak instance gets lost.
  // This can be removed once we stop depending on window redirects.
  if (to.name && to.name !== "Login") {
    const identityServiceAuthentication = storage.get("identity-login");

    if (identityServiceAuthentication) {
      await identity.checkSSO();
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isLoggedIn) {
    next({ name: "Login" }); // does not require auth, make sure to always call next()!
    return;
  }

  const { requiredPermissions, requiredFeature } = to.meta || {};

  if (requiredPermissions && !Permission.hasPermissions("view", requiredPermissions)) {
    next({ name: "Dashboard", params: to.params });
    return;
  }

  if (requiredFeature && !FeatureFlag.isFeatureEnabled(requiredFeature)) {
    next({ name: "Dashboard", params: to.params });
    return;
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  if (to.name === "Dashboard" && !process.env.VUE_APP_CYPRESS) {
    const { lang = "de" } = to.params;
    const { ciso = false, app_id } = store.getters.companyData;

    if (ciso) {
      window.location.replace(`${store.getters.serverConfig.infoSec}/${lang}`);
    } else {
      window.location.replace(`${store.getters.serverConfig.privacy}/${lang}/dashboard?platformId=${app_id}`);
    }
  }

  next();
});

export default router;
