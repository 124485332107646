<template>
  <div>
    <div class="dg-accordion">
      <slot />
    </div>
  </div>
</template>

<script>
import "./Accordion.scss";

export default {
  name: "dg-accordion",
  data() {
    return {
      isActive: null,
    };
  },
  methods: {
    setActive(idx) {
      if (this.isActive === idx) {
        return (this.isActive = null);
      }

      return (this.isActive = idx);
    },
  },
};
</script>
