<template>
  <div class="dg-info-box" @mouseout="hover = false">
    <i :class="`${iconClass}`" @mouseover="hover = true">
      <svg viewBox="0 0 4 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.66667 4.66667C2.66667 4.26667 2.4 4 2 4H0V5.33333H1.33333V9.33333H0V10.6667H4V9.33333H2.66667V4.66667Z"
          fill="#C63040"
        />
        <path
          d="M1.66675 2.66667C2.21903 2.66667 2.66675 2.21896 2.66675 1.66667C2.66675 1.11439 2.21903 0.666672 1.66675 0.666672C1.11446 0.666672 0.666748 1.11439 0.666748 1.66667C0.666748 2.21896 1.11446 2.66667 1.66675 2.66667Z"
          fill="#C63040"
        />
      </svg>
    </i>
    <div :class="`dg-info-box-content ${contentClass}`" @mouseover="hover = true">
      <h3 v-if="label">{{ label }}</h3>
      <p v-if="message">{{ message }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
// assets
import "./InfoBox.scss";

export default {
  name: "DgInfoBox",
  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },
    bottomLeft: {
      type: Boolean,
      default: false,
    },
    bottomRight: {
      type: Boolean,
      default: false,
    },
    topRight: {
      type: Boolean,
      default: false,
    },
    topLeft: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    iconClass: function() {
      return this.isSmall ? "small-icon" : "";
    },
    contentClass: function() {
      const additionalClass = this.hover ? "show-content" : "";

      if (this.bottomLeft) {
        return "bottom left " + additionalClass;
      }
      if (this.bottomRight) {
        return "bottom right " + additionalClass;
      }
      if (this.topLeft) {
        return "top left " + additionalClass;
      }
      if (this.topRight) {
        return "top right " + additionalClass;
      }
      return "bottom left " + additionalClass;
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
