export default [
  {
    path: "c/:id/ppm",
    component: () => import(/* webpackChunkName: "ppm-dashboard" */ "./Layout.vue"),
    children: [
      {
        name: "PPMDashboard",
        path: "",
        component: () => import(/* webpackChunkName: "ppm-dashboard" */ "./views/Dashboard.vue"),
        redirect: { name: "PPMList" },
        children: [
          {
            name: "PPMList",
            path: "",
            meta: {
              title: "PPM Dashboard",
              featureName: "PPM",
              requiredPermissions: [{ action: "view", feature: "portal_for_dpas_and_jcas" }],
            },
            component: () => import(/* webpackChunkName: "ppm-dashboard" */ "./views/List.vue"),
          },
          {
            name: "PPMInfoSection",
            path: "info",
            meta: {
              title: "PPM InfoSection",
              featureName: "PPM",
              requiredPermissions: [{ action: "view", feature: "portal_for_dpas_and_jcas" }],
            },
            component: () => import(/* webpackChunkName: "ppm-info-section" */ "./views/InfoSection.vue"),
          },
        ],
      },
      {
        name: "PPMUpload",
        path: "upload",
        meta: {
          title: "Upload PPM",
          featureName: "PPM",
          requiredPermissions: [
            { action: "view", feature: "portal_for_dpas_and_jcas" },
            { action: "add", feature: "portal_for_dpas_and_jcas_upload_workflow" },
          ],
        },
        component: () => import(/* webpackChunkName: "ppm-upload" */ "./views/Upload.vue"),
      },
      {
        name: "PPMReview",
        path: "review",
        meta: {
          title: "Review PPM",
          featureName: "PPM",
          requiredPermissions: [
            { action: "view", feature: "portal_for_dpas_and_jcas" },
            { action: "add", feature: "portal_for_dpas_and_jcas_review_workflow" },
          ],
        },
        component: () => import(/* webpackChunkName: "ppm-review" */ "./views/Review.vue"),
      },
      {
        name: "PPMCreate",
        path: "create",
        meta: {
          title: "Create PPM",
          featureName: "PPM",
          requiredPermissions: [
            { action: "view", feature: "portal_for_dpas_and_jcas" },
            { action: "add", feature: "portal_for_dpas_and_jcas_creation_workflow" },
          ],
        },
        component: () => import(/* webpackChunkName: "ppm-create" */ "./views/Create.vue"),
      },
      {
        name: "PPMDetails",
        path: "details/:ppmId",
        meta: {
          title: "Privacy Contract Details",
          featureName: "PPM",
          requiredPermissions: [{ action: "view", feature: "portal_for_dpas_and_jcas" }],
        },
        component: () => import(/* webpackChunkName: "ppm-details" */ "./views/Details.vue"),
      },
    ],
  },
];
