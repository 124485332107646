<template>
  <div :class="[`dg-form-editor dg-input`]">
    <label v-if="label" :for="_name">
      {{ label }}
      <span class="required" v-if="required">*</span>
    </label>
    <TinymceEditor
      v-model="value"
      api-key="blaosq7y2mbt13ds2zmi3z5wvtoxko7zsfs741x4p69nxwem"
      @onChange="onChange"
      @onBlur="onBlur"
      :init="{
        height: 300,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
      }"
    />

    <p v-if="onError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
// assets
import "./Editor.scss";
import TinymceEditor from "@tinymce/tinymce-vue";

export default {
  name: "DgEditor",
  components: {
    TinymceEditor,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    initialValue: { type: String, default: "" },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    validator: {
      type: Function,
      default(input) {
        return !!input;
      },
    },
  },
  data() {
    return {
      onError: false,
      value: this.initialValue,
    };
  },
  methods: {
    onChange(content) {
      if (this.required) {
        if (!!this.value && this.validator(this.value)) {
          this.onError = false;
        }
      } else if (this.value === "") {
        this.onError = false;
      } else {
        if (this.validator(this.value)) {
          this.onError = false;
        }
      }

      this.$emit("input", this.value);
      this.$emit("change", this.value);
    },
    onBlur(content) {
      if (this.required) {
        if (!this.value && !this.validator(this.value)) {
          this.onError = true;
        }
      } else if (this.value !== "") {
        if (!this.validator(this.value)) {
          this.onError = true;
        }
      }
    },
  },
  computed: {
    _name: function() {
      if (this.name !== "") {
        return this.name;
      } else {
        return this.label
          .toLowerCase()
          .replace(" ", "-")
          .replace("?", "-");
      }
    },
  },
};
</script>
