<template>
  <div :class="['dg-toggle', { disabled: disabled }]" @click="onChange">
    <span class="dg-yes">Yes</span>
    <span class="dg-no" :class="{ muted: isChecked === true }">No</span>
    <div class="dg-slider" :class="{ active: isChecked === true }"></div>
  </div>
</template>

<script>
import { DgButton } from "../../basic/";

import "./Toggle.scss";

export default {
  name: "dg-toggle",
  data() {
    return {
      isChecked: this.checked,
    };
  },
  components: {},
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange() {
      this.isChecked = this.disabled ? this.isChecked : !this.isChecked;
      this.$emit("change", this.isChecked);
    },
  },
};
</script>
