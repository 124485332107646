<template>
  <div class="dg-chat-questions">
    <div class="consultant-avatar" v-if="consultantAvatar.length > 0" :style="`margin-top: ${avatarMarginTop}px`">
      <img :src="consultantAvatar" alt="Consultant" />
    </div>
    <div class="questions">
      <DgChatQuestion
        v-for="(question, index) in questions"
        :key="index"
        :label="question.label"
        :helpLabel="question.helpLabel"
        :helpMessage="question.helpMessage"
        :active="index === activeQuestion"
        :order="index + 1"
        @click="setActiveQuestion(index)"
        ref="questions"
        ><DgForm :fields="question.questions"
      /></DgChatQuestion>
    </div>
  </div>
</template>

<script>
// assets
import "./ChatQuestions.scss";
import { DgChatQuestion, DgForm } from "../";

export default {
  name: "DgChatQuestions",
  components: {
    DgChatQuestion,
    DgForm,
  },
  props: {
    questions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    consultantAvatar: {
      type: String,
      default: "",
    },
  },
  methods: {
    setActiveQuestion(index) {
      this.activeQuestion = index;
    },
    updateAvatarPosition() {
      let contentHeight = 0;
      const questions = this.$refs.questions;
      questions.forEach((question, index) => {
        if (this.activeQuestion > index && !question.$el.className.includes("inactive-question")) {
          contentHeight = question.$el.children[0].children[1].clientHeight;
        }
      });
      this.avatarMarginTop = questions[this.activeQuestion].$el.offsetTop - contentHeight;
    },
  },
  beforeUpdate() {
    this.updateAvatarPosition();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateAvatarPosition);
    });
  },
  data() {
    return {
      answers: {},
      activeQuestion: 0,
      avatarMarginTop: 0,
    };
  },
};
</script>
