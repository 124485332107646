import Vue from "vue";

// FIXME: We should have a service for HTTP calls.
export default {
  state: {
    editForm: {
      id: null,
      nextId: null,
      type: "edit", // edit or create
      isOpen: false,
    },
    showAddForm: false,
    showCloseConfirmation: false,
    todos: {},
    availableAssignees: {},
  },
  actions: {
    async fetchAvailableAssignees({ rootState, commit }) {
      const { id: customerId } = rootState.user.companyData;
      const url = `/api1/v2/customers/${customerId}/todos/assignees`;
      const response = await Vue.prototype.$http.get(url);

      const { data: assignees } = response.data;
      commit("setAvailableAssignees", assignees);
    },
    async fetchTodos({ rootState, commit }) {
      const { id: customerId } = rootState.user.companyData;
      const url = `/api1/v2/customers/${customerId}/todos`;
      const response = await Vue.prototype.$http.get(url);

      const { data: todos } = response.data;
      commit("setTodos", todos);
    },
    async saveTodo({ rootState, commit }, todoAttributes) {
      const { id: customerId } = rootState.user.companyData;

      let url = `/api1/v2/customers/${customerId}/todos`;
      let method = "post";

      if (todoAttributes?.id) {
        url = url.concat(`/${todoAttributes.id}`);
        method = "patch";
      }

      const response = await Vue.prototype.$http({
        url,
        method,
        data: todoAttributes,
      });

      const { data: todo } = response.data;
      commit("setTodo", todo);
    },
    async deleteTodo({ rootState, commit }, todoId) {
      const { id: customerId } = rootState.user.companyData;
      const url = `/api1/v2/customers/${customerId}/todos/${todoId}`;

      await Vue.prototype.$http.delete(url);

      commit("removeTodo", todoId);
    },
    tryOpenForm({ rootState, commit }, { type, id = null }) {
      const { editForm, showAddForm } = rootState.todos;
      if (!editForm.isOpen && !showAddForm) {
        type === "edit" ? commit("openEditForm", { type, id }) : commit("toggleAddForm", true);
        return;
      }

      editForm.type = type;
      editForm.nextId = id;
      commit("toggleCloseConfirmation", true);
    },
  },
  mutations: {
    setAvailableAssignees(state, assignees) {
      state.availableAssignees = assignees.reduce((assignees, assignee) => {
        return { ...assignees, [assignee.id]: assignee };
      }, {});
    },
    setTodos(state, todos) {
      state.todos = todos.reduce((todos, todo) => {
        return { ...todos, [todo.id]: { ...todo, showEditForm: false } };
      }, {});
    },
    setTodo(state, todo) {
      state.todos = { ...state.todos, [todo.id]: { ...todo, showEditForm: false } };
    },
    removeTodo(state, todoId) {
      Vue.delete(state.todos, todoId);
    },
    toggleCloseConfirmation(state, flag) {
      state.showCloseConfirmation = flag;
    },
    openEditForm(state, { type, id }) {
      state.editForm = {
        id,
        type,
        isOpen: true,
      };
    },
    closeEditForm(state) {
      state.editForm = {
        id: null,
        nextItemId: null,
        type: "edit", // edit or create
        isOpen: false,
      };
    },
    toggleAddForm(state, flag) {
      state.showAddForm = flag;
    },
  },
};
