<template>
  <div class="dg-language">
    <span
      :class="[
        'dg-language__item',
        {
          active: supportedLocale !== locale,
        },
      ]"
      v-for="supportedLocale in supportedLocales"
      :key="supportedLocale"
      v-html="localeFlags[supportedLocale]"
      @click="onChangeLocale($event, supportedLocale)"
      :data-testId="'lang-' + supportedLocale"
    ></span>
  </div>
</template>

<script>
// assets
import "./Language.scss";

export default {
  name: "DgLanguage",
  props: {
    supportedLocales: {
      type: Array,
      default: () => {
        return ["de", "en"];
      },
    },
    defaultLocale: {
      type: String,
      default: "de",
    },
  },
  data() {
    return {
      localeFlags: {
        de: `<svg width="24" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 6H0V1a1 1 0 011-1h22a1 1 0 011 1v5z" fill="#000" />
            <path d="M24 6H0v6h24V6z" fill="#E00" />
            <path d="M24 17a1 1 0 01-1 1H1a1 1 0 01-1-1v-5h24v5z" fill="#FDCF00" />
          </svg>`,
        en: `<svg width="24" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23 0H1a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1z"
                fill="#002781"
              />
              <path
                d="M24 1a1 1 0 00-1-1h-2.58L14 4.938V0h-4v4.938L3.58 0H1a1 1 0 00-1 1v1.293L6.12 7H0v4h6.12L0 15.707V17a1 1 0 001 1h2.58L10 13.062V18h4v-4.938L20.42 18H23a1 1 0 001-1v-1.293L17.88 11H24V7h-6.12L24 2.293V1z"
                fill="#E6E6E6"
              />
              <path d="M24 8H13V0h-2v8H0v2h11v8h2v-8h11V8z" fill="#D10D24" />
              <path
                d="M23.5.153L14.6 7h1.64L24 1.03V1a.986.986 0 00-.5-.847zM16.24 11H14.6l8.9 6.846c.294-.174.5-.48.5-.846v-.031L16.24 11zM7.76 11L0 16.969V17c0 .366.206.672.5.846L9.4 11H7.76zM7.76 7H9.4L.5.153A.986.986 0 000 1v.03L7.76 7z"
                fill="#D10D24"
              />
            </svg>`,
      },
      locale: this.$router.currentRoute.params.lang || this.defaultLocale,
    };
  },
  methods: {
    onChangeLocale(e, lang) {
      if (this.locale === lang) {
        e.stopPropagation();
        return;
      }

      const { fullPath } = this.$router.currentRoute;
      window.location.href = fullPath.replace(this.locale, lang);
    },
  },
};
</script>
