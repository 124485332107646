import axios from "axios";
export default {
  addDataCategoryToSubject({ commit }, { subject, data }) {
    commit("ADD_DATA_CATEGORY_TO_DATA_SUBJECT", {
      dataSubject: subject,
      dataCategories: data,
    });
  },
  addStateToToogle({ commit }, { data }) {
    commit("SET_MAPPING_CHANGED_STATE", data);
  },
  toggleEntity({ commit }, { checked, auditProcessId, questionGroupId, entity, entityKeyName }) {
    if (checked) {
      commit("ADD_ENTITY_TO_DATA_EXCHANGES", {
        auditProcessId,
        questionGroupId,
        entity,
        entityKeyName,
      });
    } else {
      commit("REMOVE_ENTITY_FROM_DATA_EXCHANGES", {
        auditProcessId,
        questionGroupId,
        entity,
        entityKeyName,
      });
    }
  },
  setSelectedEntities({ commit }, { data, entityKeyName }) {
    switch (entityKeyName) {
      case "senders":
        commit("SET_SELECTED_SENDERS", data);
        break;
      case "receivers":
        commit("SET_SELECTED_RECEIVERS", data);
        break;
      case "data_subjects":
        commit("SET_SELECTED_DATA_SUBJECTS", data);
        break;
    }
  },
  setMappingData({ commit }, { keyName, data, entityKeyName }) {
    switch (keyName) {
      case "organisations":
        if (entityKeyName && !Array.isArray(entityKeyName)) {
          entityKeyName = [entityKeyName];
        }
        entityKeyName.forEach(e => {
          commit("SET_MAPPING_ORGANISATIONS", { data, entityKeyName: e });
        });
        break;
      case "organisation_parts":
        if (entityKeyName && !Array.isArray(entityKeyName)) {
          entityKeyName = [entityKeyName];
        }
        entityKeyName.forEach(e => {
          commit("SET_MAPPING_ORGANISATION_PARTS", { data, entityKeyName: e });
        });
        break;
      case "organisation_types":
        commit("SET_MAPPING_ORGANISATION_TYPES", data);
        break;
      case "organisation_part_types":
        commit("SET_MAPPING_ORGANISATION_PART_TYPES", data);
        break;
      default:
        commit(keyName, data);
    }
  },
  removeSelectedEntities({ commit }, { entityKeyName, selectedEntities, removedEntities }) {
    commit("REMOVE_SELECTED_ENTITIES", { entityKeyName, selectedEntities, removedEntities });
  },
  setDataExchangesForEntities({ commit }, { data, entityKeyName }) {
    commit("SET_MAPPING_DATA_EXCHANGES", { data, entityKeyName });
  },
  async loadOrganisations({ commit, dispatch }) {
    commit("START_LOADING");
    await Promise.all(
      ["organisation_types", "organisation_part_types", "organisations", "organisation_parts"].map(resource => {
        axios.get(`/api1/v2/data_exchanges/${resource}`).then(response => {
          dispatch("setMappingData", {
            keyName: resource,
            data: response.data.data,
            entityKeyName: ["senders", "receivers", "data_subjects"],
          });
        });
      })
    );
    commit("STOP_LOADING");
  },
};
