<template>
  <div class="dgd-templates-block">
    <div class="mydg-right-content-wrapper">
      <div class="dgd-avv-content">
        <div v-if="isLoading" class="shimmer-wrapper">
          <div>
            <div class="shine box"></div>
            <div class="shim-container">
              <div class="shine lines"></div>
              <div class="shine lines"></div>
              <div class="shine lines"></div>
            </div>
          </div>
          <div>
            <div class="shine box"></div>
            <div class="shim-container">
              <div class="shine lines"></div>
              <div class="shine lines"></div>
              <div class="shine lines"></div>
            </div>
          </div>
          <div>
            <div class="shine box"></div>
            <div class="shim-container">
              <div class="shine lines"></div>
              <div class="shine lines"></div>
              <div class="shine lines"></div>
            </div>
          </div>
        </div>
        <div v-else-if="dataBlocks.length" class="box-slider">
          <button
            v-on:click="controlSlider('next')"
            class="slider-btn btn-right"
            v-bind:class="{
              active: Math.abs(sliderPosition) <= (dataBlocks.length - 2) * this.blockSize,
            }"
          >
            <img src="./images/btn-right.svg" />
          </button>

          <button
            v-bind:class="{ active: sliderPosition != 0 }"
            v-on:click="controlSlider('previous')"
            class="slider-btn btn-left"
          >
            <img src="./images/btn-left.svg" />
          </button>

          <div :style="{ width: blockfullSize + 'px', left: sliderPosition + 'px' }" class="sliderItem">
            <div v-for="(row, i) in dataBlocks" :key="i" class="news-post">
              <div class="cover">
                <img :src="row.featuredImage" :alt="row.featuredImageAltText" />
              </div>
              <div class="text-post">
                <p class="date">{{ getFormattedDate(row.publishDate) }}</p>
                <h3>
                  <a :href="row.url" target="_blank">{{ row.name }}</a>
                </h3>
                <div class="short-text">
                  <p>{{ stripHtml(row.postBody) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mydg-no-content" v-else>
          <h2>Oops!</h2>
          <p>No content found.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DgSlider",
  props: {
    dataBlocks: {
      type: Array,
      default: () => {
        return [];
      },
    },
    blockSize: {
      type: Number,
      default: 320,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sliderPosition: 0,
    };
  },
  watch: {
    sliderPosition(n, o) {
      this.$emit("pos", n);
    },
  },
  methods: {
    getFormattedDate(d) {
      var timestamp = new Date(d);
      var dd = String(timestamp.getDate()).padStart(2, "0");
      var mm = String(timestamp.getMonth() + 1).padStart(2, "0");
      var yyyy = timestamp.getFullYear();

      timestamp = `${dd}.${mm}.${timestamp.getFullYear()}`;
      return timestamp;
    },
    controlSlider(state) {
      if (state == "next") {
        this.sliderPosition -= this.blockSize;
      } else if (state == "previous" && this.sliderPosition < 0) {
        this.sliderPosition += this.blockSize;
      }
    },
    stripHtml(html) {
      let temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return temporalDivElement.textContent || temporalDivElement.innerText || "";
    },
  },
  computed: {
    blockfullSize() {
      return parseInt(this.dataBlocks.length) * this.blockSize;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-right {
  right: 6px;
}

.btn-left {
  left: 9px;
}

.slider-btn {
  opacity: 1;
  width: 48px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  height: 48px;
  background: #254a5d;
  text-align: center;
  border: none;
  border-radius: 100px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;

  &:not(.active) {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover .btn-right,
  &:hover .btn-left {
    opacity: 1;
  }

  &:hover,
  &:active {
    outline: none;
  }
}

.sliderItem,
.mydg-no-content {
  position: relative;
  transition: all 0.4s;
  min-height: 400px;
}

.mydg-no-content {
  background-color: $white;
  font-size: 25px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mydg-no-content p {
  font-size: 16px;
}

.box-slider {
  overflow: hidden;
  position: relative;
  min-height: 400px;
  padding-left: 80px;
  z-index: 1;
}

.box-slider:before {
  background: rgba(0, 51, 73, 0.74);
  height: 100%;
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 100px;
  z-index: 2;
}

.box-slider:after {
  background: rgba(0, 51, 73, 0.74);
  height: 100%;
  content: "";
  position: absolute;
  left: -15px;
  top: 0;
  width: 100px;
  z-index: 2;
}

.text-post {
  margin: 10px;
}

.news-post {
  position: static;
  float: left;
  height: 380px;
  width: 300px;
  overflow: hidden;
  margin: 6px 15px 8px 5px;
  left: 40px;
  top: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  flex: none;
  order: 0;
  align-self: center;

  h3,
  p {
    margin: 0;
    padding: 0;
  }

  h3 {
    display: block;
    margin-bottom: 10px;
  }

  h3 a {
    text-decoration: none;
    color: #003349;
  }

  .cover {
    height: 152px;
    background: #ddd;
    overflow: hidden;
  }

  .cover img {
    width: 100%;
  }

  p.date {
    color: #cb333b;
    font-weight: bold;
    font-size: 11px;
  }
}

.short-text {
  margin-bottom: 20px;
  font-size: 11px;
  line-height: 2;
}

.shimmer-wrapper {
  display: flex;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;

  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 300px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  .box {
    height: 250px;
    width: 419px;
  }

  .shim-container {
    display: inline-flex;
    flex-direction: column;
    margin-left: 25px;
    margin-top: 15px;
    vertical-align: top;
  }

  .lines {
    height: 10px;
    margin-top: 10px;
    width: 200px;
  }

  .photo {
    display: block !important;
    width: 325px;
    height: 100px;
    margin-top: 15px;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
