import Vue from "vue";
import { FeatureFlag, commentNode } from "@/services";

Vue.prototype.$release = FeatureFlag.isFeatureEnabled;

// Register a global custom directive called `v-enabled`
/**
 * You can use this directive on any element/component
 * For eg. <button v-enabled="feature_name">Create Entry</button>
 */
Vue.directive("release", {
  bind(el, binding, vnode) {
    /**
     * value will contain the feature name
     */
    const { value = "" } = binding;
    if (!FeatureFlag.isFeatureEnabled(value)) commentNode(el, vnode);
  },
});
