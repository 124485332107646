import { mapGetters } from "vuex";

export default {
  data() {
    return {
      locale: this.$route.params.lang || "",
    };
  },
  computed: {
    featureLinkDetails() {
      return feature => {
        const currentRouteName = this.$route.meta.featureName;
        return currentRouteName ? this.featureLinkKey(currentRouteName, feature) : {};
      };
    },
    featureURL() {
      return feature => {
        const featureLinkDetails = this.featureLinkDetails(feature);
        if (featureLinkDetails) {
          return this.locale.includes("en") ? featureLinkDetails.link_en : featureLinkDetails.link_de;
        }
        return "#";
      };
    },
    isFeatureEnabled() {
      return feature => {
        const featureLinkDetails = this.featureLinkDetails(feature);
        if (featureLinkDetails) {
          return featureLinkDetails.active;
        }
        return false;
      };
    },

    ...mapGetters(["featureLinkKey"]),
  },
};
