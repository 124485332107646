export default [
  {
    path: "c/:id/dg_suite",
    component: () => import(/* webpackChunkName: "dg-suite" */ "./Layout"),
    props: true,
    children: [
      {
        name: "DGSuiteOverview",
        path: "/",
        component: () => import(/* webpackChunkName: "dg-suite" */ "./views/Overview"),
        meta: {
          featureName: "DGSuite",
          title: "DataGard Suite",
          requiresAuth: true,
        },
      },
      {
        name: "DGSuiteProductDetails",
        path: "details/:product_id",
        component: () => import(/* webpackChunkName: "dg-suite-product-details" */ "./views/Details"),
        meta: {
          featureName: "DGSuite",
          title: "DataGuard Suite",
          requiresAuth: true,
        },
      },
      {
        path: "cookie_manager",
        component: () => import(/* webpackChunkName: "dg-suite-cookie-manager" */ "./layouts/CookieManager"),
        children: [
          {
            name: "CookieManagerOverview",
            path: "/",
            component: () => import(/* webpackChunkName: "dg-suite-cookie-manager" */ "./views/CookieManager/Overview"),
            meta: {
              featureName: "DGSuite",
              title: "Consent Management Overview",
              requiresAuth: true,
            },
          },
          {
            name: "CookieManagerConfiguration",
            path: ":website_id/configuration",
            component: () =>
              import(/* webpackChunkName: "dg-suite-cookie-manager-config" */ "./views/CookieManager/Configuration"),
            meta: {
              featureName: "DGSuite",
              title: "Consent Management Configuration",
              requiresAuth: true,
            },
            props: true,
          },
          {
            name: "CookieManagerAccess",
            path: ":website_id/access",
            component: () =>
              import(/* webpackChunkName: "dg-suite-cookie-manager-access" */ "./views/CookieManager/Access"),
            meta: {
              featureName: "DGSuite",
              title: "Consent Management Access",
              requiresAuth: true,
            },
            props: true,
          },
          {
            name: "CookieManagerIntegration",
            path: ":website_id/integration",
            component: () =>
              import(/* webpackChunkName: "dg-suite-cookie-manager-integration" */ "./views/CookieManager/Integration"),
            meta: {
              featureName: "DGSuite",
              title: "Consent Management Integration",
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
      {
        name: "PreferenceManagementOverview",
        path: "preference_management",
        component: () => import(/* webpackChunkName: "dg-suite-preference-mgmt" */ "./layouts/PreferenceManagement"),
      },
      {
        name: "WhistleblowingOverview",
        path: "whistleblowing",
        component: () => import(/* webpackChunkName: "dg-suite-whistle-blowing" */ "./layouts/Whistleblowing"),
      },
    ],
  },
];
