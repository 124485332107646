<template>
  <header class="dg-header">
    <div class="container-fluid">
      <div class="row">
        <div class="d-none d-md-block col-md-2 col-lg-2">
          <DgLogo :url="homeUrl" />
        </div>
        <div
          :class="{
            'col-8 d-md-none d-flex align-items-center': isSidebarActive,
            'd-none': !isSidebarActive,
          }"
        >
          <DgHamburgerButton :isActive="sidebarMenuVisibility" @click="toggleSidebarMenu" />
          <span class="dg-header__name">{{ companyData.name }}</span>
        </div>
        <div class="col-md-10 col-lg-7">
          <DgTopNavbar :navItems="navItems" />
        </div>
        <div class="col-12 col-lg-3 text-right">
          <div
            class="dg-user-info"
            data-testId="dg-user-info"
            :class="{ 'dropdown-active': state.showDropdown }"
            @click="toggleDropdown"
          >
            <div class="dg-user-info-text">
              <div class="dg-user-info__initials">
                {{ userInitials }}
              </div>
              <p class="dg-display-name">
                {{ userName }}
              </p>
              <p class="dg-company">
                {{ companyData.name }}
              </p>
            </div>

            <div class="dg-user-info-dropdown">
              <div class="dg-languages">
                <DgLanguage />
              </div>

              <div class="dg-companies" data-testId="dg-user-related">
                <ul v-if="basicInfoTitle">
                  <li @click="goToBasicInfo">
                    <span>{{ basicInfoTitle }}</span>
                  </li>
                </ul>
                <ul v-if="hasResetLinks">
                  <li @click="askForReset(resetUser)" v-for="(resetUser, index) in companyData.reset_link" :key="index">
                    <span>{{ resetUser.title }}</span>
                  </li>
                  <li :key="index" v-for="(item, index) in companyData.extra_links" v-on:click="gotoUrl(item.link)">
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
                <template v-if="hasCustomers">
                  <p class="dg-label">Company</p>
                  <ul>
                    <li
                      @click="companyClick(singleCompany.id)"
                      v-for="(singleCompany, index) in userData.customers"
                      :key="index"
                    >
                      <span>{{ singleCompany.name }}</span>
                    </li>
                  </ul>
                </template>
              </div>
              <div class="dg-logout">
                <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.01895 11.1961L12.9999 7.21512L9.01895 3.23413L7.74366 4.50942L9.54746 6.31322H3.60754V8.11702H9.54746L7.74366 9.92082L9.01895 11.1961Z"
                    fill="#C63040"
                  />
                  <path
                    d="M9.92091 12.6266H1.8038V1.8038H9.92091V0H0.901901C0.404052 0 0 0.404052 0 0.901901V13.5285C0 14.0264 0.404052 14.4304 0.901901 14.4304H9.92091V12.6266Z"
                    fill="#C63040"
                  />
                </svg>
                <a href @click.prevent="doLogout" data-testId="dg-logout">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { DgLogo, DgHamburgerButton, DgLanguage } from "../../basic/";
import DgTopNavbar from "../TopNavbar/TopNavbar";

// assets
import "./Header.scss";

export default {
  name: "DgHeader",
  components: {
    DgLogo,
    DgHamburgerButton,
    DgTopNavbar,
    DgLanguage,
  },
  props: {
    companyData: {
      type: Object,
    },
    userData: {
      type: Object,
    },
    homeUrl: {
      type: String,
    },
    basicInfoTitle: {
      type: String,
    },
    navItems: {
      type: Array,
    },
    sidebarMenuVisibility: {
      type: Boolean,
      default: false,
    },
    isSidebarActive: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      state: {
        showDropdown: false,
      },
    };
  },
  computed: {
    hasCustomers() {
      return "customers" in this.userData;
    },
    hasResetLinks() {
      return "reset_link" in this.companyData;
    },
    hasFirstAndLastName() {
      return "first_name" in this.userData && "last_name" in this.userData;
    },
    userInitials() {
      if (this.hasFirstAndLastName) return this.userData.first_name.charAt(0) + this.userData.last_name.charAt(0);
      else return "";
    },
    userName() {
      if (this.hasFirstAndLastName) return `${this.userData.first_name} ${this.userData.last_name}`;
      else return "";
    },
  },
  created() {
    window.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    toggleSidebarMenu() {
      this.$emit("toggleSidebarMenu");
    },
    doLogout() {
      this.$emit("doLogout");
    },
    companyClick(cid) {
      this.$emit("companyClick", cid);
    },
    toggleDropdown() {
      this.state.showDropdown = !this.state.showDropdown;
    },
    closeDropdown(e) {
      if (!this.$el.contains(e.target)) {
        this.state.showDropdown = false;
      }
    },
    gotoUrl(url) {
      window.location.href = `/redirect${url}`;
    },
    goToBasicInfo() {
      this.$emit("onBasicInfoClick");
    },
    askForReset(resetUser) {
      this.$emit("resetUser", resetUser);
    },
  },
};
</script>
