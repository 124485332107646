<template>
  <div class="dg-multiselect" v-click-outside="hideList">
    <label
      @click="showList()"
      :class="[
        'multiselect-label',
        { 'label-up': showDropdown || this.selectedList.length > 0 },
        { 'label-focus': showDropdown },
      ]"
      >{{ label }}</label
    >
    <ul
      :class="['multiselect-selectedList', { 'multiselect-focus': showDropdown }]"
      ref="multiselectList"
      @click.prevent="cursorOn($event)"
    >
      <li :class="[`multiselect-selected ${variant}`]" v-for="(selected, index) in selectedList" :key="index">
        {{ selected }}
        <a class="multiselect-close" href="#" @click.prevent="removeFromSelectedList(selected)">
          <img src="../../assets/icons/circle-cross-12.svg" />
        </a>
      </li>
      <li class="multiselect-input">
        <div>
          <input
            ref="multiselectquery"
            @input="queryChange($event)"
            @keyup="expand($event)"
            @focus="showList()"
            @blur="showList()"
            v-model="query"
            type="text"
          />
          <a href="#" class="multiselect-clearAll" @click.prevent="clearSelected" v-show="selectedList.length > 0">
            <img src="../../assets/icons/circle-cross-16.svg" />
          </a>
        </div>
      </li>
    </ul>
    <ul :class="['multiselect-unselectedList']" v-if="showDropdown">
      <li
        :class="[
          'multiselect-unselected',
          { 'multiselect-unselected--selected': selectedList.indexOf(listItem) !== -1 },
        ]"
        @click="addToSelectedList(listItem)"
        v-for="(listItem, index) in currentList"
        v-bind:key="index"
      >
        <img
          class="multiselect-check"
          v-if="selectionVisible && selectedList.indexOf(listItem) !== -1"
          src="../../assets/icons/active.svg"
        />
        <img v-else class="multiselect-check" src="../../assets/icons/inactive.svg" />

        <span>{{ listItem }}</span>
      </li>
      <li v-if="none && !customOptions" class="multiselect-empty">No results found</li>
    </ul>
  </div>
</template>

<script>
import "./Multiselect.scss";
import "../../directives/clickOutside/"; // v-click-outside will be broken on storybook

export default {
  name: "dg-multiselect",
  props: {
    list: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selectionVisible: {
      type: Boolean,
      default: true,
    },
    customOptions: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    selectedValues: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      currentList: [],
      selectedList: [],
      customList: [],
      query: null,
      showDropdown: false,
      none: false,
    };
  },

  watch: {
    list(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        // for now let's keep it like this since it's not working with cehckIfArraysAreEqual for some reason
        this.currentList = JSON.parse(JSON.stringify(newVal));
      }
    },
    selectedValues(newVal, oldVal) {
      if (!this.cehckIfArraysAreEqual(newVal, oldVal)) {
        this.selectedList = JSON.parse(JSON.stringify(newVal));
      }
    },
    query(newVal, oldVal) {
      if (newVal) {
        this.currentList = this.list
          .filter((val, index) => {
            return !this.selectedList.includes(val);
          })
          .filter((val, index) => {
            return val.toLowerCase().indexOf(newVal.toLowerCase()) !== -1;
          });
      } else {
        if (!this.selectionVisible) {
          this.currentList = this.list.filter((val, index) => {
            return !this.selectedList.includes(val);
          });
        } else {
          this.currentList = this.list;
        }
      }
      this.currentList.length === 0 ? (this.none = true) : (this.none = false);
    },
  },

  mounted() {
    this.currentList = this.list;
    this.selectedList = this.selectedValues || [];
  },

  methods: {
    cehckIfArraysAreEqual(array1, array2) {
      if (array1.length === 0 && array2.length === 0) {
        return true;
      }
      for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {
          if (array1[i] === array2[j]) {
            return true;
          }
        }
      }
      return false;
    },
    showList() {
      this.showDropdown = true;
    },
    hideList() {
      this.showDropdown = false;
    },
    addToSelectedList(l) {
      this.showDropdown = true;
      if (!this.selectionVisible) {
        this.selectedList.push(l);
        this.currentList = this.currentList.filter((val, index) => {
          return val != l;
        });
      } else {
        if (this.selectedList.indexOf(l) === -1) {
          this.selectedList.push(l);
        } else {
          this.removeFromSelectedList(l);
        }
      }
      this.query = null;
      this.currentList.length === 0 ? (this.none = true) : (this.none = false);
      this.$emit("selection", this.selectedList);
    },

    removeFromSelectedList(s) {
      this.selectedList = this.selectedList.filter((val, index) => {
        return val != s;
      });
      if (!this.selectionVisible) {
        this.currentList.push(s);
      }
      this.$emit("selection", this.selectedList);
    },

    queryChange(e) {
      this.query = e.target.value;
    },

    expand(e) {
      let obj = e.target;
      let code = e.which || e.code;
      if (!obj.savesize) obj.savesize = obj.size;
      let selected = document.querySelector(".multiselect-unselected--active");

      switch (code) {
        case 40:
          if (selected) {
            if (selected === document.querySelector(".multiselect-unselectedList li:last-child")) {
              selected.classList.remove("multiselect-unselected--active");
              document
                .querySelector(".multiselect-unselectedList li:first-child")
                .classList.add("multiselect-unselected--active");
            } else if (selected !== document.querySelector(".multiselect__empty")) {
              selected.classList.remove("multiselect-unselected--active");
              selected.nextElementSibling.classList.add("multiselect-unselected--active");
            }
          } else {
            document
              .querySelector(".multiselect-unselectedList li:first-child")
              .classList.add("multiselect-unselected--active");
          }
          break;
        case 38:
          if (selected) {
            if (selected === document.querySelector(".multiselect-unselectedList li:first-child")) {
              selected.classList.remove("multiselect-unselected--active");
              document
                .querySelector(".multiselect-unselectedList li:last-child")
                .classList.add("multiselect-unselected--active");
            } else if (selected !== document.querySelector(".multiselect-empty")) {
              selected.classList.remove("multiselect-unselected--active");
              selected.previousElementSibling.classList.add("multiselect-unselected--active");
            }
          } else {
            document
              .querySelector(".multiselect-unselectedList li:first-child")
              .classList.add("multiselect-unselected--active");
          }
          break;
        case 13:
          if (selected !== document.querySelector(".multiselect-empty")) {
            this.addToSelectedList(document.querySelector(".multiselect-unselected--active").innerText);
          }
          break;
        case 32:
          if (this.customOptions && this.list.indexOf(this.query) === -1) {
            this.list.push(this.query);
            this.addToSelectedList(this.query);
          }
          break;
      }
    },

    cursorOn(e) {
      this.$refs.multiselectquery.focus();
    },

    handleFocusOut() {
      this.showDropdown = false;
    },

    delSelected() {
      if (!this.query) {
        this.selectedList.pop();
        if (!this.selectionVisible) {
          this.currentList = this.list.filter((val, index) => {
            return !this.selectedList.includes(val);
          });
          this.currentList.length === 0 ? (this.none = true) : (this.none = false);
        }
      }
    },
    clearSelected() {
      this.selectedList = [];
      this.currentList = this.list;
      this.showDropdown = false;
      document.querySelector(".multiselect-unselectedList li:first-child") &&
        document
          .querySelector(".multiselect-unselectedList li:first-child")
          .classList.add("multiselected-unselected--active");
      this.currentList.length === 0 ? (this.none = true) : (this.none = false);
      this.$emit("selection", this.selectedList);
    },
  },
};
</script>
