<template>
  <div class="dg-form-checkbox dg-input" v-if="show">
    <input
      type="checkbox"
      :id="_name"
      :name="_name"
      :value="_name"
      v-bind:checked="checked"
      class="dg-input"
      @input="onInput"
      :required="required"
    />
    <label v-if="label" :for="_name">{{ label }}</label>

    <p v-if="onError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
// assets
import "./Checkbox.scss";

export default {
  name: "dg-checkbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    validator: {
      type: Function,
      default(input) {
        return true;
      },
    },
  },
  data() {
    return {
      onError: false,
    };
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.checked);
    },
  },
  computed: {
    _name: function() {
      if (this.name !== "") {
        return this.name;
      } else {
        return this.label
          .toLowerCase()
          .replace(" ", "-")
          .replace("?", "-");
      }
    },
  },
};
</script>
