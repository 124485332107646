export default [
  {
    path: "c/:id/news/",
    component: () => import(/* webpackChunkName: "news" */ "./Layout.vue"),
    props: true,
    children: [
      {
        name: "NewsPortal",
        path: "/",
        component: () => import(/* webpackChunkName: "news" */ "./views/Newsportal.vue"),
        meta: {
          requiresAuth: true,
          title: "News Portal",
          featureName: "News Portal",
          translationKey: "news_portal",
        },
      },
    ],
  },
];
