<template>
  <div class="dg-dt-table">
    <div
      class="dg-dt-table__container"
      :class="{
        'dg-dt-table__container--all-borders': allBorders,
        'dg-dt-table__container--no-borders': noBorders,
      }"
      :style="computedHeight"
    >
      <div class="dg-dt-table__header">
        <div class="dg-dt-table__header-cell dg-dt-table__header-cell--selectable" v-if="isSelectionEnabled">
          <input
            type="checkbox"
            name="dg-dt-table__select-all"
            id="dg-dt-table__select-all"
            :disabled="isSelectionSingle"
            v-model="multiSelect"
            @change="selectAllRows"
          />
        </div>
        <div
          class="dg-dt-table__header-cell"
          :class="{ sortable: col.sortable }"
          v-for="col in columns"
          :key="col.field"
          :style="{ width: `${col.width || defaultColWidth}px` }"
          @click="sortWithColumn(col)"
        >
          {{ col.title }}
          <span
            class="dg-dt-table__header--sortable"
            :class="{
              ascending: col.field === currentSortColumn && currentSortOrder === 'asc',
              descending: col.field === currentSortColumn && currentSortOrder === 'desc',
            }"
            v-if="col.sortable"
          />
        </div>
      </div>
      <div class="dg-dt-table__body">
        <div
          class="dg-dt-table__row"
          :class="{
            'dg-dt-table__row--selected': selectedRowIds.indexOf(item[uniqueRowKey]) !== -1,
          }"
          v-for="item in processedRows"
          :key="item[uniqueRowKey]"
        >
          <div class="dg-dt-table__row-cell dg-dt-table__row-cell--selectable" v-if="isSelectionEnabled">
            <input
              type="checkbox"
              :name="`dg-dt-table__${item[uniqueRowKey]}`"
              :id="`dg-dt-table__${item[uniqueRowKey]}`"
              :checked="selectedRowIds.indexOf(item[uniqueRowKey]) !== -1"
              @change="ev => selectRow(item, ev)"
            />
          </div>
          <div
            class="dg-dt-table__row-cell"
            v-for="col in columns"
            :key="col.field"
            :style="{ width: `${col.width || defaultColWidth}px` }"
          >
            <template v-if="col.render">
              <component :is="col.render" :params="{ data: item }" />
            </template>
            <template v-else>{{ item[col.field] }}</template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPaginationEnabled" class="dg-dt-table__pagination">
      <div class="dg-dt-table__pagination__left-section">
        <select class="dg-dt-table__pagination__select-size" v-model="pageSize" @change="changePageSize">
          <option :value="0" disabled selected>Choose</option>
          <option v-for="option in pageSizeOptions" :key="option" :value="option">{{ option }}</option>
        </select>
        <p class="dg-dt-table__pagination__results">Showing {{ currentPage }} of {{ pagination.totalPages }} results</p>
      </div>
      <div class="dg-dt-table__pagination__right-section">
        <DgButton @click="prevPage" :disabled="currentPage === 1">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.292999 8.29294L8 0.585938L9.414 1.99994L3.414 7.99994H21C21.553 7.99994 22 8.44794 22 8.99994C22 9.55194 21.553 9.99994 21 9.99994H3.414L9.414 15.9999L8 17.4139L0.292999 9.70694C-0.0980015 9.31594 -0.0980015 8.68394 0.292999 8.29294Z"
              fill="white"
            />
          </svg>
        </DgButton>
        <ul class="dg-dt-table__pagination__controls">
          <li
            v-for="page in pagination.totalPages"
            v-bind:key="page"
            :class="{
              active: page === currentPage,
            }"
          >
            <DgButton class="dg-dt-table__pagination__btns" :onlyText="true" @click="jumpToPage(page)">
              {{ page }}
            </DgButton>
          </li>
        </ul>
        <DgButton @click="nextPage" :disabled="currentPage === pagination.totalPages">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.707 8.29294L14 0.585938L12.586 1.99994L18.586 7.99994H1C0.447 7.99994 0 8.44794 0 8.99994C0 9.55194 0.447 9.99994 1 9.99994H18.586L12.586 15.9999L14 17.4139L21.707 9.70694C22.098 9.31594 22.098 8.68394 21.707 8.29294Z"
              fill="white"
            />
          </svg>
        </DgButton>
      </div>
    </div>
  </div>
</template>

<script>
import DgButton from "../../basic/Button/Button.vue";
// assets
import "./DataTable.scss";

export default {
  name: "dg-table",
  components: {
    DgButton,
  },
  props: {
    /**
     * selectable values:
     * "": disable selection
     * single: select only one row
     * multiple: select multiple rows by holding ctrl key and click on rows
     * attatch @onSelect event on table to get selected row data(s).
     */
    selectable: {
      type: String,
      default: "",
      validator: value => {
        // Only accepts values that contain the string 'single' or 'multiple'.
        return ["", "single", "multiple"].indexOf(value) !== -1;
      },
    },
    /**
     * height for virtualization data table is necessary
     */
    height: {
      type: String,
      default: "",
    },
    /**
     * For all borders around cells
     */
    allBorders: {
      type: Boolean,
      default: false,
    },
    /**
     * For no borders around cells
     */
    noBorders: {
      type: Boolean,
      default: false,
    },
    /**
     * data for rendering table
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * column definitions
     * document: https://www.ag-grid.com/javascript-grid-column-definitions/
     */
    columns: {
      type: Array,
      required: true,
    },
    /**
     * pagination:
     * settings for providing pagination options
     * provide pageSize, totalPages, allowPageSizeChange
     */
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Default column width if individual column width is not provided
     */
    defaultColWidth: {
      type: String,
      default: "150",
    },
  },
  data() {
    const { defaultPage = 1, pageSizeOptions = [5, 10, 20], pageSize } = this.pagination;
    const defaultPageSize = pageSizeOptions.indexOf(pageSize) !== -1 ? pageSize : 0;
    return {
      currentSortColumn: "",
      currentSortOrder: "",
      selectedRowIds: [],
      multiSelect: false,
      currentPage: defaultPage,
      pageSize: defaultPageSize,
      pageSizeOptions,
    };
  },
  computed: {
    /**
     * Checks if pagination object is passed as prop
     */
    isPaginationEnabled() {
      return Object.keys(this.pagination).length !== 0;
    },
    /**
     * This works in tandem with sorting feature of data table
     * We sort a copy of rows passed by the parent component
     */
    processedRows() {
      const unsortedRows = [...this.rows];
      if (this.currentSortColumn) {
        switch (this.currentSortOrder) {
          case "asc":
            return unsortedRows.sort((a, b) => {
              return ("" + a[this.currentSortColumn]).localeCompare(b[this.currentSortColumn]);
            });
            break;
          case "desc":
            return unsortedRows.sort((a, b) => {
              return ("" + b[this.currentSortColumn]).localeCompare(a[this.currentSortColumn]);
            });
            break;
          default:
            return unsortedRows;
            break;
        }
      } else {
        return unsortedRows;
      }
    },
    /**
     * Checks if parent component wants this data table to have selection enabled
     */
    isSelectionEnabled() {
      return Boolean(this.selectable);
    },
    /**
     * Checks if parent component wants this data table to have only single selection enabled
     */
    isSelectionSingle() {
      return this.selectable === "single";
    },
    /**
     * Compute height based on height prop value
     */
    computedHeight() {
      return this.height ? { height: `${this.height}px` } : {};
    },
    /**
     * Get uniqueRowKey for selection purposes
     * Parent component can also set a different unique key
     * By passing isUnique to any column other than default id
     */
    uniqueRowKey() {
      let uniqueKey = "id";
      this.columns.forEach(col => {
        if (col.isUnique) {
          uniqueKey = col.field;
        }
      });
      return uniqueKey;
    },
  },
  methods: {
    /**
     * Notify parent component if user selects a row
     * If selection is single will return a single row object
     * If selecton is multiple will return an array of row objects data
     */
    handleSelection(selected) {
      this.$emit("onSelect", selected);
    },
    /**
     * Notify the parent component if user clicked on sortable column
     * column will contain column field
     * sortOrder will contain ASC, DESC or NONE
     */
    notifySortChange(column, sortOrder) {
      this.$emit("onSort", { column, sortOrder });
    },
    /**
     * This notifies the parent component that the page has changed
     * the consumer will slice the data or fetch the page data from backend
     */
    pageChange() {
      this.resetSelection();
      this.$emit("pageClick", this.currentPage);
    },
    /**
     * Change page if user clicks directly on a page number
     */
    jumpToPage(pageNum) {
      this.currentPage = pageNum;
      this.pageChange();
    },
    /**
     * Go to previous page
     */
    prevPage() {
      this.currentPage -= 1;
      this.pageChange();
    },
    /**
     * Go to next page
     */
    nextPage() {
      this.currentPage += 1;
      this.pageChange();
    },
    /**
     * Sort table with given column
     * col represents a single col data from columns
     * this function will trigger change in current rows data
     */
    sortWithColumn(col) {
      if (!col.sortable) return;
      let sortOrder = this.currentSortOrder;
      if (this.currentSortColumn != col.field) {
        sortOrder = "";
      }
      switch (sortOrder) {
        case "asc":
          sortOrder = "desc";
          break;
        case "desc":
          sortOrder = "";
          break;
        default:
          sortOrder = "asc";
          break;
      }
      this.currentSortColumn = col.field;
      this.currentSortOrder = sortOrder;
    },
    /**
     * Notify parent component about the selected or unselected rows
     */
    notifySelection() {
      const selectedRows = this.processedRows.filter(row => this.selectedRowIds.indexOf(row[this.uniqueRowKey]) !== -1);
      this.$emit("onSelect", selectedRows);
    },
    /**
     * Reset selection values
     * This is needed when page is changed as we don't need to the selection
     * Can be used in other scenarios
     */
    resetSelection() {
      this.multiSelect = false;
      this.selectedRowIds = [];
      this.notifySelection();
    },
    /**
     * Selects or Deselects all rows at once
     * This function is triggered when user clicks on first column checkbox
     */
    selectAllRows() {
      if (this.multiSelect) {
        this.selectedRowIds = [...this.processedRows.map(item => item[this.uniqueRowKey])];
      } else {
        this.selectedRowIds = [];
      }
      this.notifySelection();
    },
    /**
     * Selects or deselects a single row
     * This function is triggered when user clicks on first column of each unique row
     */
    selectRow(row, e) {
      const rowId = row[this.uniqueRowKey];
      const indexOfRow = this.selectedRowIds.indexOf(rowId);
      if (e.target.checked && indexOfRow === -1) {
        if (this.isSelectionSingle) {
          this.selectedRowIds = [rowId];
        } else {
          this.selectedRowIds.push(rowId);
        }
      } else {
        this.selectedRowIds.splice(indexOfRow, 1);
      }
      this.notifySelection();
    },
    /**
     * Notify parent to change the page size
     */
    changePageSize() {
      this.currentPage = 1;
      this.$emit("onPageSizeChange", this.pageSize);
    },
  },
};
</script>
