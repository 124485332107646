import Vue from "vue";
import axios from "axios";

export default {
  state: {
    dpaListData: {},
    fetchingDPAListData: false,
    ppmDetails: {},
    fetchingPPMDetails: false,
    subContractorTree: null,
    selectedContractId: null,
    refreshSubcontractorTree: false,
  },
  getters: {
    dpaListData(state) {
      return state.dpaListData;
    },
    fetchingDPAList(state) {
      return state.fetchingDPAListData;
    },
    ppmDetails(state) {
      return state.ppmDetails;
    },
    fetchingPPMDetails(state) {
      return state.fetchingPPMDetails;
    },
    refreshSubcontractorTree(state) {
      return state.refreshSubcontractorTree;
    },
    selectedContractId(state) {
      return state.selectedContractId;
    },
    subContractorTree(state) {
      return state.subContractorTree;
    },
    selectedContract(state) {
      if (!state.subContractorTree) return null;

      let controller = null;
      let processor = null;
      const currentContractId = state.selectedContractId;

      function findContract(currentContract) {
        /**
         * if we already found our controller and processor then return
         */
        if (controller && processor) return;
        /**
         * if there are no subcontracts then return
         */
        if (!currentContract.sub_contracts) return;

        const processingPartner = currentContract.sub_contracts.find(child => currentContractId === child.id);
        if (processingPartner) {
          controller = currentContract;
          processor = processingPartner;
          return;
        }

        /**
         * if we don't find our controller and processor then search in the sub_contracts list
         */
        currentContract.sub_contracts.forEach(contract => {
          findContract(contract);
        });
      }

      findContract(state.subContractorTree);

      if (controller && processor) {
        return {
          controller,
          processor,
        };
      }

      return null;
    },
  },
  actions: {
    async fetchDPAListData({ commit }, { cid, metaKeys = {} }) {
      const queryStringArray = [];
      Object.keys(metaKeys).forEach(key => {
        if (key && metaKeys[key]) queryStringArray.push(`${key}=${metaKeys[key]}`);
      });
      const queryString = queryStringArray.join("&");
      commit("SET_FETCHING_DPA", true);
      try {
        const { data } = await axios.get(`/api1/v2/customers/${cid}/privacy_contracts?${queryString}`);
        commit("SET_DPA_LIST", data);
        commit("SET_SELECTED_CONTRACT_ID", null);
      } finally {
        commit("SET_FETCHING_DPA", false);
      }
    },
    async fetchPPMDetails({ commit, rootGetters }, { cid, ppmId }) {
      commit("SET_FETCH_PPM_DETAILS", true);
      const { data } = await axios.get(`/api1/v2/customers/${cid}/privacy_contracts/${ppmId}`);
      commit("SET_PPM_DETAILS", data.data);
      commit("SET_SUBCONTRACTOR_TREE", {
        ppmDetails: data.data,
        companyData: rootGetters.companyData,
      });
      commit("SET_FETCH_PPM_DETAILS", false);
    },
    updatePPMFiles({ commit }, newListOfFiles) {
      commit("SET_PPM_FILES", newListOfFiles);
    },
  },
  mutations: {
    SET_DPA_LIST(state, { data = [], pagination = {} }) {
      state.dpaListData = { list: data, pagination };
    },
    SET_FETCHING_DPA(state, flag) {
      state.fetchingDPAListData = flag;
    },
    SET_FETCH_PPM_DETAILS(state, flag) {
      state.fetchingPPMDetails = flag;
    },
    SET_PPM_DETAILS(state, newPpmDetails) {
      const partnerDetails = newPpmDetails.partners[0];
      const partnerContactPerson = newPpmDetails.partners[0].contacts[0];
      state.ppmDetails = {
        partnerDetails,
        partnerContactPerson,
        ...newPpmDetails,
      };
    },
    SET_SELECTED_CONTRACT_ID(state, partnerId) {
      state.selectedContractId = partnerId;
    },
    SET_SUBCONTRACTOR_TREE(state, { ppmDetails, companyData }) {
      const partnerDetails = ppmDetails.partners[0];
      const partnerName = partnerDetails.name;
      const companyName = companyData.name;
      let controllerName = partnerName;
      let processorName = companyName;
      let controllerDetails = { ...partnerDetails };
      let processorDetails = {};
      if (ppmDetails.customer_role === "controller") {
        controllerName = companyName;
        processorName = partnerName;
        controllerDetails = {};
        processorDetails = { ...partnerDetails };
      }
      state.subContractorTree = {
        id: 0,
        partner_name: controllerName,
        partner_info: { ...controllerDetails },
        include_children: true,
        is_customer: ppmDetails.customer_role === "controller",
        sub_contracts: [
          {
            id: ppmDetails.id,
            partner_name: processorName,
            partner_info: { ...processorDetails },
            contract_data: { ...ppmDetails },
            include_children: ppmDetails.include_children,
            is_customer: ppmDetails.customer_role === "processor",
          },
        ],
      };
    },
    UPDATE_SUBCONTRACTOR_TREE(state, { nodeId, subContractList, contractData, partnerInfo, refresh = false }) {
      function updateSubcontractorTree(arr) {
        arr.forEach(i => {
          if (nodeId === i.id) {
            if (subContractList) Vue.set(i, "sub_contracts", subContractList);
            if (partnerInfo) i.partner_info = partnerInfo;
            if (contractData) Vue.set(i, "contract_data", contractData);
            /**
             * This flag is used to update d3-mitch-tree library
             */
            state.refreshSubcontractorTree = refresh;
          } else {
            if (i.sub_contracts) updateSubcontractorTree(i.sub_contracts);
          }
        });
      }
      updateSubcontractorTree(state.subContractorTree.sub_contracts);
    },
    RESET_REFRESH_SUBCONTRACT_TREE(state) {
      // Invoke this mutation once the d3 tree is updated with newly added subcontract
      state.refreshSubcontractorTree = false;
    },
    SET_PPM_FILES(state, newListOfFiles) {
      state.ppmDetails = {
        ...state.ppmDetails,
        files: newListOfFiles,
      };
    },
  },
};
