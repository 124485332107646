<template>
  <div class="badge">
    <div :class="[{ completed: completed }]">
      <a @click.prevent="badgeClick" class="dgdf-check-completed"></a>
    </div>
    <span class="label">{{ label }}</span>
    <div class="hexagon hexagon2">
      <div class="hexagon-in1">
        <div :class="[{ 'hexagon-act-in2': completed, 'hexagon-in2': !completed }]"></div>
      </div>
    </div>
  </div>
</template>

<script>
// assets
import "./Badge.scss";

export default {
  name: "DgBadge",
  props: {
    label: {
      type: String,
      default: "",
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    badgeClick() {
      this.$emit("badgeClick");
    },
  },
};
</script>
