<template>
  <div data-testId="dataGuard-logo" class="dg-logo">
    <a v-if="url" :href="url">
      <svg width="128" height="20" viewBox="0 0 128 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 19.7584H6.73348C12.8643 19.7584 16.6184 15.8658 16.6184 10.255C16.6184 4.61745 12.7292 0.697987 6.65246 0.697987H0V19.7584ZM2.34969 17.6376V2.81879H6.67947C11.1628 2.81879 14.1877 5.90604 14.1877 10.255C14.1877 14.5503 11.2708 17.6376 6.59844 17.6376H2.34969Z"
          fill="white"
        />
        <path
          d="M24.0491 19.9195C26.1828 19.9195 27.8032 18.953 28.7485 17.396H28.7755V19.7584H30.8821V6.68456H28.6945V8.99329L28.6405 9.02013C27.6952 7.43624 25.9937 6.52349 24.0491 6.52349C20.3221 6.52349 17.6483 9.44967 17.6483 13.3154C17.6483 17.2886 20.4301 19.9195 24.0491 19.9195ZM24.3732 17.9329C21.6725 17.9329 19.8629 15.9463 19.8629 13.3154C19.8629 10.4161 21.8885 8.51007 24.3732 8.51007C26.966 8.51007 28.7755 10.6309 28.7755 13.3154C28.7755 16.0805 26.804 17.9329 24.3732 17.9329Z"
          fill="white"
        />
        <path
          d="M37.567 19.9195C39.1335 19.9195 40.8787 19.0142 41.6079 18.5847C41.6079 18.5847 41.3312 18.2179 41.0729 17.8144C40.8146 17.411 40.5932 17.0075 40.5932 17.0075C39.945 17.3565 38.7284 17.8792 37.9451 17.8792C36.7028 17.8792 36.3517 17.1275 36.3517 15.5436V8.56376H40.6999V6.68456H36.3517V1.98658L34.164 2.22819V6.68456H32.4625V8.56376H34.164V15.8658C34.164 18.4966 35.2443 19.9195 37.567 19.9195Z"
          fill="white"
        />
        <path
          d="M47.6104 19.9195C49.744 19.9195 51.3645 18.953 52.3097 17.396H52.3367V19.7584H54.4434V6.68456H52.2557V8.99329L52.2017 9.02013C51.2564 7.43624 49.5549 6.52349 47.6104 6.52349C43.8833 6.52349 41.2095 9.44967 41.2095 13.3154C41.2095 17.2886 43.9913 19.9195 47.6104 19.9195ZM47.9345 17.9329C45.2337 17.9329 43.4241 15.9463 43.4241 13.3154C43.4241 10.4161 45.4497 8.51007 47.9345 8.51007C50.5272 8.51007 52.3367 10.6309 52.3367 13.3154C52.3367 16.0805 50.3652 17.9329 47.9345 17.9329Z"
          fill="white"
        />
        <path
          d="M66.1785 19.9463C69.1224 19.9463 71.9852 18.9799 73.7407 17.5839V9.36913H66.1776V12.6175H70.0406V15.4094C68.8253 16.1611 67.5559 16.5101 66.1785 16.5101C62.5865 16.5101 60.3718 13.7718 60.3718 10.255C60.3718 6.36242 62.8565 4.02685 66.0165 4.02685C68.1231 4.02685 69.7706 4.83222 70.9589 6.01342L73.6327 3.7047C71.6611 1.5302 68.7443 0.456377 66.0165 0.456377C60.0207 0.456377 56.4017 4.67114 56.4017 10.255C56.4017 16.3758 60.6419 19.9463 66.1785 19.9463Z"
          fill="white"
        />
        <path
          d="M80.7898 20C82.2752 20 83.6796 19.3826 84.6789 18.1745L84.7329 18.2013V19.7584H88.298V6.63087H84.7329V13.0201C84.7329 15.5436 83.3555 16.8322 81.8161 16.8322C80.1686 16.8322 79.4394 16.0268 79.4394 13.6376V6.63087H75.9284V14.5503C75.9284 18.5503 77.927 20 80.7898 20Z"
          fill="white"
        />
        <path
          d="M96.2976 20C98.0261 20 99.3495 19.3557 100.268 18.1745L100.322 18.2013V19.7584H103.698V6.63087H100.16V8.13423L100.106 8.16107C99.1604 7.03356 97.756 6.44295 96.2166 6.44295C92.6785 6.44295 90.0048 9.26175 90.0048 13.2349C90.0048 17.2886 92.6785 20 96.2976 20ZM96.9728 16.8322C94.9742 16.8322 93.5968 15.3557 93.5968 13.2617C93.5968 11.0067 95.0552 9.58389 96.9728 9.58389C98.8633 9.58389 100.322 11.1409 100.322 13.2617C100.322 15.4094 98.7823 16.8322 96.9728 16.8322Z"
          fill="white"
        />
        <path
          d="M106.115 19.7584H109.653V13.6913C109.653 11.7047 110.815 10.255 113.002 10.255C113.218 10.255 113.57 10.255 113.948 10.2819V6.44295H113.678C112.138 6.44295 110.707 7.19463 109.707 8.53691H109.653V6.63087H106.115V19.7584Z"
          fill="white"
        />
        <path
          d="M120.519 20C122.301 20 123.679 19.3289 124.597 18.1477H124.624V19.7584H128V0H124.489V8.16107H124.435C123.463 7.03356 122.058 6.44295 120.438 6.44295C116.9 6.44295 114.199 9.26175 114.199 13.2349C114.199 17.2886 116.873 20 120.519 20ZM121.167 16.8322C119.195 16.8322 117.791 15.3557 117.791 13.2617C117.791 11.0067 119.276 9.58389 121.167 9.58389C123.193 9.58389 124.624 11.1409 124.624 13.2617C124.624 15.4094 123.112 16.8322 121.167 16.8322Z"
          fill="white"
        />
      </svg>
    </a>
    <svg v-else width="128" height="20" viewBox="0 0 128 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 19.7584H6.73348C12.8643 19.7584 16.6184 15.8658 16.6184 10.255C16.6184 4.61745 12.7292 0.697987 6.65246 0.697987H0V19.7584ZM2.34969 17.6376V2.81879H6.67947C11.1628 2.81879 14.1877 5.90604 14.1877 10.255C14.1877 14.5503 11.2708 17.6376 6.59844 17.6376H2.34969Z"
        fill="white"
      />
      <path
        d="M24.0491 19.9195C26.1828 19.9195 27.8032 18.953 28.7485 17.396H28.7755V19.7584H30.8821V6.68456H28.6945V8.99329L28.6405 9.02013C27.6952 7.43624 25.9937 6.52349 24.0491 6.52349C20.3221 6.52349 17.6483 9.44967 17.6483 13.3154C17.6483 17.2886 20.4301 19.9195 24.0491 19.9195ZM24.3732 17.9329C21.6725 17.9329 19.8629 15.9463 19.8629 13.3154C19.8629 10.4161 21.8885 8.51007 24.3732 8.51007C26.966 8.51007 28.7755 10.6309 28.7755 13.3154C28.7755 16.0805 26.804 17.9329 24.3732 17.9329Z"
        fill="white"
      />
      <path
        d="M37.567 19.9195C39.1335 19.9195 40.8787 19.0142 41.6079 18.5847C41.6079 18.5847 41.3312 18.2179 41.0729 17.8144C40.8146 17.411 40.5932 17.0075 40.5932 17.0075C39.945 17.3565 38.7284 17.8792 37.9451 17.8792C36.7028 17.8792 36.3517 17.1275 36.3517 15.5436V8.56376H40.6999V6.68456H36.3517V1.98658L34.164 2.22819V6.68456H32.4625V8.56376H34.164V15.8658C34.164 18.4966 35.2443 19.9195 37.567 19.9195Z"
        fill="white"
      />
      <path
        d="M47.6104 19.9195C49.744 19.9195 51.3645 18.953 52.3097 17.396H52.3367V19.7584H54.4434V6.68456H52.2557V8.99329L52.2017 9.02013C51.2564 7.43624 49.5549 6.52349 47.6104 6.52349C43.8833 6.52349 41.2095 9.44967 41.2095 13.3154C41.2095 17.2886 43.9913 19.9195 47.6104 19.9195ZM47.9345 17.9329C45.2337 17.9329 43.4241 15.9463 43.4241 13.3154C43.4241 10.4161 45.4497 8.51007 47.9345 8.51007C50.5272 8.51007 52.3367 10.6309 52.3367 13.3154C52.3367 16.0805 50.3652 17.9329 47.9345 17.9329Z"
        fill="white"
      />
      <path
        d="M66.1785 19.9463C69.1224 19.9463 71.9852 18.9799 73.7407 17.5839V9.36913H66.1776V12.6175H70.0406V15.4094C68.8253 16.1611 67.5559 16.5101 66.1785 16.5101C62.5865 16.5101 60.3718 13.7718 60.3718 10.255C60.3718 6.36242 62.8565 4.02685 66.0165 4.02685C68.1231 4.02685 69.7706 4.83222 70.9589 6.01342L73.6327 3.7047C71.6611 1.5302 68.7443 0.456377 66.0165 0.456377C60.0207 0.456377 56.4017 4.67114 56.4017 10.255C56.4017 16.3758 60.6419 19.9463 66.1785 19.9463Z"
        fill="white"
      />
      <path
        d="M80.7898 20C82.2752 20 83.6796 19.3826 84.6789 18.1745L84.7329 18.2013V19.7584H88.298V6.63087H84.7329V13.0201C84.7329 15.5436 83.3555 16.8322 81.8161 16.8322C80.1686 16.8322 79.4394 16.0268 79.4394 13.6376V6.63087H75.9284V14.5503C75.9284 18.5503 77.927 20 80.7898 20Z"
        fill="white"
      />
      <path
        d="M96.2976 20C98.0261 20 99.3495 19.3557 100.268 18.1745L100.322 18.2013V19.7584H103.698V6.63087H100.16V8.13423L100.106 8.16107C99.1604 7.03356 97.756 6.44295 96.2166 6.44295C92.6785 6.44295 90.0048 9.26175 90.0048 13.2349C90.0048 17.2886 92.6785 20 96.2976 20ZM96.9728 16.8322C94.9742 16.8322 93.5968 15.3557 93.5968 13.2617C93.5968 11.0067 95.0552 9.58389 96.9728 9.58389C98.8633 9.58389 100.322 11.1409 100.322 13.2617C100.322 15.4094 98.7823 16.8322 96.9728 16.8322Z"
        fill="white"
      />
      <path
        d="M106.115 19.7584H109.653V13.6913C109.653 11.7047 110.815 10.255 113.002 10.255C113.218 10.255 113.57 10.255 113.948 10.2819V6.44295H113.678C112.138 6.44295 110.707 7.19463 109.707 8.53691H109.653V6.63087H106.115V19.7584Z"
        fill="white"
      />
      <path
        d="M120.519 20C122.301 20 123.679 19.3289 124.597 18.1477H124.624V19.7584H128V0H124.489V8.16107H124.435C123.463 7.03356 122.058 6.44295 120.438 6.44295C116.9 6.44295 114.199 9.26175 114.199 13.2349C114.199 17.2886 116.873 20 120.519 20ZM121.167 16.8322C119.195 16.8322 117.791 15.3557 117.791 13.2617C117.791 11.0067 119.276 9.58389 121.167 9.58389C123.193 9.58389 124.624 11.1409 124.624 13.2617C124.624 15.4094 123.112 16.8322 121.167 16.8322Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
// assets
import "./Logo.scss";

export default {
  name: "dg-logo",
  props: {
    url: {
      type: String,
    },
  },
};
</script>
