export default [
  {
    path: "c/:id/todos",
    component: () => import(/* webpackChunkName: "todos" */ "./Layout.vue"),
    props: true,
    children: [
      {
        name: "Todos",
        path: "/",
        component: () => import(/* webpackChunkName: "todos" */ "./views/index.vue"),
        meta: {
          title: "DataGard ToDos",
          translationKey: "todos",
          requiresAuth: true,
          featureName: "ToDos",
        },
      },
    ],
  },
];
