<template>
  <span @click="backButton" class="breadcrumb"> <img src="../../assets/icons/arrow-left-12.svg" /> Back </span>
</template>

<script>
import "./Breadcrumb.scss";

export default {
  name: "DgBreadcrumb",
  methods: {
    backButton() {
      this.$emit("buttonClick");
    },
  },
};
</script>
