import Toast from "./Toast.vue";

const DgToast = {
  install(Vue, options) {
    this.EventBus = new Vue();

    Vue.component("DgToast", Toast);

    Vue.prototype.$dgToast = {
      show(params) {
        DgToast.EventBus.$emit("show", params);
      },
    };
  },
};

export default DgToast;
