<template>
  <DgInput
    type="email"
    :name="name"
    :label="label"
    :value="value"
    :required="required"
    :placeholder="placeholder"
    :errorMessage="errorMessage"
    :initialValue="initialValue"
    :validator="validator"
    @input="onInput"
  />
</template>

<script>
import { DgInput } from "../";
import validator from "email-validator";
export default {
  name: "dg-email",
  components: { DgInput },
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    initialValue: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(input, event) {
      this.$emit("input", input, event);
    },
    validator(input) {
      return validator.validate(input);
    },
  },
};
</script>
