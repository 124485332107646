<template>
  <div class="dg-form-checkboxes dg-input">
    <label v-if="label">
      {{ label }}
      <span class="required" v-if="required">*</span>
    </label>
    <div class="dg-checkboxes">
      <DgCheckbox
        v-if="selectAll"
        :name="'select_all_options_' + Math.floor(Math.random() * Math.floor(9999))"
        :label="getSelectAllLabel()"
        @input="onSelectAll"
        v-model="selectAllVar"
      />
      <DgCheckbox
        v-for="(choice, index) in value"
        :key="index"
        :name="choice.label"
        :label="choice.label"
        :checked="choice.checked"
        :show="choice.show"
        @input="onItemSelect($event, index)"
      />
    </div>

    <p v-if="onError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
// assets
import Checkbox from "./Checkbox.vue";

export default {
  name: "dg-checkboxes",
  components: {
    DgCheckbox: Checkbox,
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    selectAllLabel: {
      type: String,
      default: "Select All",
    },
    deselectAllLabel: {
      type: String,
      default: "Deselect All",
    },
    required: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
      default(input) {
        return true;
      },
    },
  },
  data() {
    return {
      selectAllVar: false,
      onError: false,
    };
  },
  methods: {
    onSelectAll(checked) {
      this.$emit(
        "input",
        this.value.map(choice => {
          return { ...choice, checked };
        })
      );
    },
    getSelectAllLabel() {
      if (!this.selectAllVar) {
        return this.selectAllLabel;
      }
      return this.deselectAllLabel;
    },
    onItemSelect(checked, index) {
      this.$emit(
        "input",
        this.value.map((choice, _index) => {
          if (index === _index) {
            return { ...choice, checked };
          }
          return { ...choice };
        })
      );
    },
  },
  computed: {
    _name: function() {
      if (this.name !== "") {
        return this.name;
      } else {
        return this.label
          .toLowerCase()
          .replace(/ /gi, "-")
          .replace(/\?/gi, "-")
          .replace(/\//gi, "-");
      }
    },
  },
};
</script>
