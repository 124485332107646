<template>
  <section :class="[`dg-section ${options.colorTheme} ${options.classes || ''}`, { 'flex-height': flexHeight }]">
    <div class="container dg-section__container">
      <div :class="['dg-section-heading', { 'dg-heading-arrow': options.image_rounded }]">
        <div
          v-if="options.imageURL"
          :class="['dg-heading-image', { 'dg-heading-rounded': options.image_rounded }]"
          :style="`background-image:url(${options.imageURL.url})`"
        ></div>
        <div v-if="description" class="dg-heading-content">
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
        </div>
      </div>
      <div class="dg-section-content">
        <div :class="['dg-section-content_box', { 'dg-section-content_box-link': options.navLink }]">
          <slot></slot>
        </div>
        <div v-if="options.navLink" class="dg-section__nav-link">
          <router-link v-if="options.navLink.internal" :to="options.navLink.link" data-testId="dg-nav-link-block">
            {{ options.navLink.title }}
          </router-link>
          <a v-else :target="options.navLink.target" :href="options.navLink.link" data-testId="dg-link-block">
            {{ options.navLink.title }}
          </a>
          <a v-if="options.helpLink" :href="options.helpLink.link" class="dg-section__nav-link__help">
            <img src="./images/question-mark.svg" />
            {{ options.helpLink.title }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// assets
import "./Section.scss";

export default {
  name: "dg-section",
  components: {},
  props: {
    flexHeight: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    options: {
      type: Object,
    },
  },
};
</script>
