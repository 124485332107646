<template>
  <div>
    <div v-if="list.length === 0" class="basket-empty">
      <span>No processes selected</span>
    </div>
    <draggable
      v-else
      tag="ul"
      class="basket-audit-list"
      v-model="basketList"
      v-bind="dragOptionsAudit"
      group="auditProcess"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <li
          v-for="(audit_process, idx) in basketList"
          :key="`${idx}-${audit_process.name}`"
          :id="idx"
          class="basket-audit"
        >
          <div class="basket-audit-parent">
            <span class="audit-name">{{ idx + 1 }} {{ audit_process.name }}</span>
            <span class="industry-name">{{ audit_process.industry }}</span>
            <DgButton
              textIcon="dgi-d-remove"
              size="x-small"
              :hover="false"
              type="primary"
              v-show="!drag"
              @click="removeAuditProcess(audit_process)"
            >
              <span>Remove</span>
            </DgButton>
          </div>
          <draggable
            tag="ul"
            class="basket-question-list"
            v-model="audit_process.question_groups"
            v-bind="dragOptionsQuestions"
            group="questionGroups"
            :move="validateDrag"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <li
                v-for="(question_group, idy) in audit_process.question_groups"
                :key="`${idy}-${question_group.name}`"
                :audit-id="audit_process.id"
                class="basket-question"
              >
                <div class="basket-question-parent">
                  <span class="basket-question-name">{{ idx + 1 }}.{{ idy + 1 }} {{ question_group.name }}</span>
                  <span class="industry-name">{{ audit_process.industry }}</span>
                  <DgButton
                    textIcon="dgi-d-remove"
                    size="x-small"
                    :hover="false"
                    type="primary"
                    v-show="!drag"
                    @click="removeQuestionGroup(audit_process, question_group)"
                  >
                    <span>Remove</span>
                  </DgButton>
                </div>
              </li>
            </transition-group>
          </draggable>
        </li>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { DgButton } from "../../../basic/";

import "./Basket.scss";

export default {
  name: "DgMcpBasket",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      orderedValue: [],
      drag: false,
    };
  },
  components: {
    draggable,
    DgButton,
  },
  computed: {
    basketList: {
      get() {
        return this.list;
      },
      set(value) {
        return this.$emit("update:list", value);
      },
    },
    dragOptionsQuestions() {
      return {
        animation: 200,
        group: "questionGroups",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragOptionsAudit() {
      return {
        animation: 200,
        group: "auditProcess",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    validateDrag(evt) {
      const draggedAuditId = evt.dragged.getAttribute("audit-id");
      const relatedAuditId = evt.related.getAttribute("audit-id");

      if (draggedAuditId !== relatedAuditId) {
        return false;
      }

      return true;
    },
    removeAuditProcess(audit) {
      const auditProcess = JSON.parse(JSON.stringify(audit));
      const industryName = auditProcess.industry;
      delete auditProcess.industry;

      return this.$parent.toggleAuditProcess(auditProcess, industryName);
    },
    removeQuestionGroup(audit, questionGroup) {
      const auditProcess = JSON.parse(JSON.stringify(audit));
      const industryName = auditProcess.industry;
      delete auditProcess.industry;

      return this.$parent.toggleQuestionGroup(industryName, auditProcess, questionGroup);
    },
  },
};
</script>
