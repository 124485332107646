export default [
  {
    path: "c/:id/dd",
    component: () => import(/* webpackChunkName: "documentation-dashboard" */ "./Layout.vue"),
    children: [
      {
        name: "DDDashboard",
        path: "",
        meta: {
          title: "Documentation Dashboard",
          featureName: "Documentation Dashboard",
          translationKey: "documentation_dashboard",
        },
        component: () => import(/* webpackChunkName: "documentation-dashboard" */ "./views/Dashboard.vue"),
      },
    ],
  },
];
