import store from "@/store";
import { FEATURES } from "./feature_list";

const isFeatureEnabled = function (featureName) {
  if (!featureName) throw new Error(`The feature name can't be blank.`);
  const featureObject = FEATURES.find(val => {
    return val["name"] == featureName.toLowerCase();
  });

  if (!featureObject) throw new Error(`The feature is not defined or can't be found`);

  return store.getters.isFeatureEnabled(featureObject["feature_key"]);
};

export default {
  isFeatureEnabled,
};
