<template>
  <div :class="[`dg-loading-indicator `]" v-if="isLoading">
    <span class="icon"></span>
    <span class="text">{{ label }}</span>
  </div>
</template>

<script>
// assets
import "./LoadingIndicator.scss";

export default {
  name: "DgLoadingIndicator",
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      default: "Fetching Data...",
    },
  },
};
</script>
