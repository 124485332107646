/**
 * axios setups.
 */

import axios from "axios";
import { Trans } from "@/translations";
import { identity } from "@/utils";
import { storage } from "@/services";

class AxiosSetup {
  constructor() {
    this.setDefaults();
    this.setInterceptors();
  }

  setDefaults = () => {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL || "/";
    axios.defaults.timeout = +process.env.VUE_APP_API_TIMEOUT || 30 * 1000;
  };

  /**
   * add validator after every axios request. if token has expired, 401 or ... .
   * also handle language before every request
   */

  /** you can override the locale in your this.$http
   *       .get(APIEndpoint, {
   *         params: { locale: locale },
   *   })
   */

  setInterceptors = () => {
    axios.interceptors.request.use(async config => {
      if (storage.get("refresh-token")) {
        await identity.refreshToken();

        const token = storage.get("access-token");
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return {
        ...config,
        params: { locale: Trans.currentLanguage, ...config.params },
      };
    });

    axios.interceptors.response.use(
      response => {
        return response;
      },
      async err => {
        if (err.response) {
          if (err.response.status === 401) {
            window.location.replace(`/login?redirect_to=${window.location}`);
            throw err;
          } else if (err.response.status === 403) {
            window.location.replace("/logout");
            throw err;
          } else if (err.response.data.hasOwnProperty("errors")) {
            // currently only dsr errors come here
            throw {
              type: "Array",
              data: err.response.data.errors,
              status: err.response.status,
              message: err.message,
            };
          } else {
            // other modules error
            throw {
              type: "Other",
              data: err.response.data,
              status: err.response.status,
              message: err.message,
            };
          }
        } else if (err.request) {
          if (err.message === "Network Error") {
            const errorMessage = "Please check your network connection";
            throw {
              type: "String",
              data: errorMessage,
            };
          } else {
            const errorMessage = "A problem occurred during connection";
            throw {
              type: "String",
              data: errorMessage,
            };
          }
        } else {
          if (axios.isCancel(err)) {
            throw {
              type: "Cancel",
              isCancel: true,
              data: err,
            };
          }
          throw {
            type: "String",
            data: err.message,
          };
        }
      }
    );
  };
}

const instance = new AxiosSetup();

export default instance;
