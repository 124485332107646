<template>
  <div class="dg-accordion-item">
    <div class="dg-accordion-header" @click.self="collapseItem()">
      <div class="dg-accordion-title" @click="collapseItem()">
        <i class="dgi dgi-small-down" :class="{ rotate: currentAccordion === activeAccordion }" />
        <span>{{ title }}</span>
      </div>
      <slot name="actions" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "dg-accordion-item",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    activeAccordion() {
      return this.$parent.isActive;
    },
    currentAccordion() {
      return this.$attrs.id;
    },
  },
  methods: {
    collapseItem() {
      this.$parent.setActive(this.currentAccordion);
    },
  },
};
</script>
