import axios from "axios";
import i18n from "./i18n";
import { defaultLocale, supportedLocales } from "../localeConfigs";

const Trans = {
  get defaultLanguage() {
    return defaultLocale;
  },
  get supportedLanguages() {
    return supportedLocales;
  },
  get currentLanguage() {
    return i18n.locale;
  },
  set currentLanguage(lang) {
    i18n.locale = lang;
  },
  /**
   * Sets the language to various services (axios, the html tag etc)
   * @param {String} lang
   * @return {String} lang
   */
  setI18nLanguageInServices(lang) {
    Trans.currentLanguage = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
  },
  /**
   * Loads new translation messages and changes the language when finished
   * @param lang
   * @return {Promise<any>}
   */
  changeLanguage(lang) {
    if (!Trans.isLangSupported(lang)) {
      return Promise.reject(new Error("Language not supported"));
    }

    return Trans.loadLanguageFile(lang).then(msgs => {
      i18n.setLocaleMessage(lang, msgs.default || msgs);
      return Trans.setI18nLanguageInServices(lang);
    });
  },
  /**
   * Async loads a translation file
   * @param lang
   * @return {Promise<*>|*}
   */
  async loadLanguageFile(lang) {
    try {
      const { data } = await axios.get("/translations");
      return data.translations[lang];
    } catch {
      // fallback for language translation strings
      return import(/* webpackChunkName: "lang-[request]" */ `../locales/${lang}.json`);
    }
  },
  /**
   * Checks if a lang is supported
   * @param {String} lang
   * @return {boolean}
   */
  isLangSupported(lang) {
    return Trans.supportedLanguages.includes(lang);
  },
  /**
   * Checks if the route's param is supported, if not, redirects to the first supported one.
   * @param {Route} to
   * @param {Route} from
   * @param {Function} next
   * @return {*}
   */
  routeMiddleware(to, from, next) {
    // Load async message files here
    const lang = to.params.lang;

    if (!Trans.isLangSupported(lang)) {
      return next(`${Trans.defaultLanguage}${to.fullPath}`);
    }

    // set lang attribute of the html element
    document.documentElement.lang = lang;

    return Trans.changeLanguage(lang).then(() => next());
  },
  /**
   * Returns a new route object that has the current language already defined
   * To be used on pages and components, outside of the main \ route, like on Headers and Footers.
   * @example <router-link :to="$i18nRoute({ name: 'someRoute'})">Click Me </router-link>
   * @param {Object} to - route object to construct
   */
  i18nRoute(to) {
    return {
      ...to,
      params: { lang: this.currentLanguage, ...to.params },
    };
  },
};

export default Trans;
