import Keycloak from "keycloak-js";
import store from "@/store";
import { storage } from "@/services";
import * as Sentry from "@sentry/vue";

let globalKeycloakInstance = null;

let globalKeycloakOptions = {
  pkceMethod: "S256",
  onLoad: "login-required",
};

const initialize = () => {
  if (globalKeycloakInstance) return;

  const serverConfig = store.getters.serverConfig;

  globalKeycloakOptions = {
    url: serverConfig.keycloak.url,
    realm: serverConfig.keycloak.realm,
    clientId: serverConfig.keycloak.clientId,
    ...globalKeycloakOptions,
  };

  globalKeycloakInstance = Keycloak(globalKeycloakOptions);

  globalKeycloakInstance.onTokenExpired = async () => {
    await refreshToken();
  };
};

const login = async () => {
  const isAuthenticated = await globalKeycloakInstance.init({
    onLoad: globalKeycloakOptions.onLoad,
    pkceMethod: globalKeycloakOptions.pkceMethod,
    enableLogging: true,
    checkLoginIframe: false,
  });

  if (!isAuthenticated) {
    window.location.reload();
  }

  storage.set("access-token", globalKeycloakInstance.token);
  storage.set("refresh-token", globalKeycloakInstance.refreshToken);
  storage.set("identity-login", true);

  return {
    accessToken: globalKeycloakInstance.token,
    refreshToken: globalKeycloakInstance.refreshToken,
  };
};

const checkSSO = async () => {
  if (!storage.get("access-token")) return;

  await globalKeycloakInstance.init({
    onLoad: "check-sso",
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
    token: storage.get("access-token"),
    refreshToken: storage.get("refresh-token"),
  });
};

const refreshToken = async () => {
  try {
    const refreshed = globalKeycloakInstance && (await globalKeycloakInstance.updateToken(30));

    if (refreshed) {
      storage.set("access-token", globalKeycloakInstance.token);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

const logout = async () => {
  const logoutOptions = { redirectUri: window.location.origin + "/login" };

  storage.clear();

  await globalKeycloakInstance.logout(logoutOptions);
};

export default {
  initialize,
  login,
  logout,
  checkSSO,
  refreshToken,
};
