export default {
  state: {
    dsrList: [],
    customer: {},
    daysLeft: null,
  },
  getters: {
    getDsrList(state) {
      let result =
        state.dsrList &&
        JSON.parse(JSON.stringify(state.dsrList)).filter(item => {
          item.created_at = formatDate(item.created_at);
          item.deadline = formatDate(item.deadline);
          delete item.right_steps;
          item.data_subject_request_workstep
            ? (item.data_subject_request_workstep = item.data_subject_request_workstep.title)
            : null;
          return item;
        });

      return result;
    },
    getDsrListByStatus(state) {
      return value => {
        let result;
        if (value === "open") {
          result = JSON.parse(JSON.stringify(state.dsrList)).filter(
            dsr => dsr.status.toLowerCase() === "active" || dsr.status.toLowerCase() === "pending"
          );
        } else if (value === "closed") {
          result = JSON.parse(JSON.stringify(state.dsrList)).filter(
            dsr => dsr.status.toLowerCase() === "finished" || dsr.status.toLowerCase() === "failed"
          );
        }
        result.forEach(item => {
          item.created_at = formatDate(item.created_at);
          item.deadline = formatDate(item.deadline);
          delete item.right_steps;
          item.data_subject_request_workstep
            ? (item.data_subject_request_workstep = item.data_subject_request_workstep.title)
            : null;
        });
        return result;
      };
    },
    getDaysLeft(state) {
      return state.daysLeft;
    },
    getDsrById(state) {
      return value => {
        let result = state.dsrList && JSON.parse(JSON.stringify(state.dsrList)).find(dsr => dsr.id === parseInt(value));
        state.daysLeft = calculateDayDiff(result.created_at, result.deadline);
        result.created_at = formatDate(result.created_at);
        result.deadline = formatDate(result.deadline);

        delete result.right_steps;

        return result;
      };
    },
    getDsrStepsById(state) {
      return value => {
        const dsr = state.dsrList && state.dsrList.find(dsr => dsr.id === parseInt(value));
        return dsr.right_steps;
      };
    },
    getCustomerDetails(state) {
      return state.customer;
    },
  },
  actions: {
    setDsrList({ commit }, dsrList) {
      commit("SET_DSR_LIST", dsrList);
    },
    setCustomerDetails({ commit }, customer) {
      commit("SET_CUSTOMER_DETAILS", customer);
    },
  },
  mutations: {
    SET_DSR_LIST(state, dsrList) {
      state.dsrList = dsrList;
    },
    UPDATE_DSR_LIST(state, dsrDetail) {
      if (state.dsrList.length) {
        state.dsrList = state.dsrList.map(dsrItem => {
          if (dsrItem.id === dsrDetail.id) {
            return dsrDetail;
          } else {
            return dsrItem;
          }
        });
      }
    },
    SET_CUSTOMER_DETAILS(state, customer) {
      state.customer = customer;
    },
  },
};
