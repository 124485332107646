import VueI18n from "vue-i18n";
import Vue from "vue";
import { defaultLocale } from "../localeConfigs";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLocale,
});

export default i18n;
