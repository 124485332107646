<template>
  <div class="mcp-header">
    <div class="mcp-header-nav">
      <h2>{{ step.id }}. {{ step.title }}</h2>
      <span v-if="step.id === 1" @click="backToCustomerSelection()">
        Back to Customers
      </span>
      <span v-if="step.id === 2" @click="backToIndustrySelection()">
        Back to 1. Industry selection
      </span>
    </div>
    <div class="mcp-header-company">
      <h1>{{ customer }}</h1>
      <ul class="mcp-header-industries">
        <li v-for="(industry, idx) in industries" :key="idx" v-show="idx <= 3">
          {{ industry }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "./Header.scss";

export default {
  name: "dg-mcp-header",
  props: {
    step: {
      type: Object,
      required: true,
    },
    customer: {
      type: String,
      required: true,
    },
    industries: {
      type: Array,
      required: false,
    },
  },
  computed: {
    customerLang() {
      return this.$route.params.lang;
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    backToIndustrySelection() {
      this.$dgToast.show({
        type: "confirm",
        title: "Data will not be saved",
        content: "Selected processes will be lost, are you sure you want to go back to industry selection?",
        confirmButtonLabel: "Yes, go back",
        rejectButtonLabel: "Cancel",
        onAccept: () => {
          this.$router.push({ name: "MCPIndustries" });
          this.$store.dispatch("setMcpActiveStep", 1);
        },
      });
    },
    backToCustomerSelection() {
      this.$dgToast.show({
        type: "confirm",
        title: "Customer selection",
        content: "Selected industries will be lost, are you sure you want to go back to customer selection?",
        confirmButtonLabel: "Yes, go back",
        rejectButtonLabel: "Cancel",
        onAccept: () => {
          window.location.replace("/redirect");
        },
      });
    },
  },
};
</script>
