<template>
  <DgInput
    type="phone"
    :name="name"
    :label="label"
    :required="required"
    :placeholder="placeholder"
    :errorMessage="errorMessage"
    :initialValue="initialValue"
    :validator="validator"
    @change="onChange"
  />
</template>

<script>
import { DgInput } from "../";
import PhoneNumber from "awesome-phonenumber";

export default {
  name: "dg-phone",
  components: { DgInput },
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    initialValue: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange(input) {
      if (this.validator(input)) {
        this.$emit("change", input);
      }
    },
    validator(input) {
      const phoneUtil = new PhoneNumber(input);
      return phoneUtil.isValid();
    },
  },
};
</script>
