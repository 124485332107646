<template>
  <div v-if="block" :class="['dgdf-it-show', `dgdf-type-${block.audit_area_id}`]">
    <div :class="['dgd-fortschitts', getStatus(block.css_status)]">
      <a :href="auditDashboardLink" class="dgdf-edit dgd-link-tooltip" v-tooltip="textEditTooltip">
        <div class="pure-progress" :data-percentage="block.css_status">
          <span class="pure-progress-left">
            <span class="pure-progress-bar"></span>
          </span>
          <span class="pure-progress-right">
            <span class="pure-progress-bar"></span>
          </span>
        </div>
      </a>
      <a :class="classForSendAuditFlow" v-tooltip="textSendTooltip" :href="sendAuditLink" @click="confirmSend"></a>

      <a
        :href="telephoneAuditLink"
        class="dgdf-phone dgd-date-two-lines dgd-link-tooltip"
        v-bind="teleBadgeCount"
        v-tooltip="tooltips.telephone_symbol"
      ></a>

      <a href="javascript:void(0);" class="dgdf-check dgd-link-tooltip" v-tooltip="textCheckTooltip"></a>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import vTooltip from "v-tooltip";

Vue.use(vTooltip);

import "./AuditFlowLink.scss";
import "./ProgressLink.scss";
export default {
  name: "AuditFlow-Links",
  props: {
    block: {
      type: Object,
      default() {
        return {};
      },
    },
    tooltips: {
      type: Object,
      default() {
        return {
          start_arrow_symbol: "",
          send_symbol: "",
          telephone_symbol: "",
          check_mark_symbol: "",
        };
      },
    },
  },
  computed: {
    teleBadgeCount() {
      return this.block.audit_changes ? { "data-changes": this.block.count_customer_audit_area } : null;
    },
    auditDashboardLink() {
      const { audit_dashboard_link } = this.block;
      if (this.hasJsVoid(audit_dashboard_link)) return audit_dashboard_link;
      return `/redirect${audit_dashboard_link}`;
    },
    documentationLink() {
      const { documentation_link } = this.block;
      if (this.hasJsVoid(documentation_link)) return documentation_link;
      return `/redirect${documentation_link}`;
    },
    sendFromDashboardLink() {
      const { send_from_dashboard_link } = this.block;
      if (this.hasJsVoid(send_from_dashboard_link)) return send_from_dashboard_link;
      return `/redirect${send_from_dashboard_link}`;
    },
    telephoneAuditLink() {
      const { telephone_link } = this.block;
      if (!telephone_link) return "javascript:void(0);";
      return `/redirect${telephone_link}`;
    },
    textSendTooltip() {
      return this.tooltips.send_symbol || "";
    },
    textEditTooltip() {
      return this.tooltips.start_arrow_symbol || "";
    },
    textCheckTooltip() {
      return this.tooltips.check_mark_symbol || "";
    },
    classForSendAuditFlow() {
      const { css_status, customer_answer_none } = this.block;
      const classNames = "dgdf-send dgd-link-tooltip";
      if (css_status === 100) return `${classNames} dgdf-actual-send`;
      else if (css_status >= 4 && css_status <= 10 && customer_answer_none) return `${classNames} dgdf-send-check`;
      return classNames;
    },
    sendAuditLink() {
      const { css_status, customer_answer_none } = this.block;
      if (css_status === 100) return this.sendFromDashboardLink;
      else if (css_status >= 4 && css_status <= 10 && !customer_answer_none) return this.documentationLink;
      return "javascript:void(0);";
    },
  },
  methods: {
    getStatus(status) {
      return `status-${status}`;
    },
    hasJsVoid(link) {
      return link.includes("javascript:void(0);");
    },
    confirmSend(ev) {
      const { css_status } = this.block;
      if (css_status === 100 && !this.hasJsVoid(this.sendAuditLink)) {
        this.$emit("confirmSend", this.sendAuditLink);
        ev.preventDefault();
      }
    },
  },
};
</script>
