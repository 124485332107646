import format from "date-fns/format";
import MarkdownIt from "markdown-it";

/**
 * useful mixin that available in all Vue components.
 */
export default {
  data() {
    return {};
  },
  filters: {},
  methods: {
    /**
     * use this method for scroll to specific element with animate
     * @param {css selector for target element} query string
     * @param {animation duration} duration number
     */

    doScrolling(query, duration) {
      const element = document.querySelector(query);

      if (!element) {
        return;
      }

      const startingY = window.pageYOffset;
      const elementY = window.pageYOffset + element.getBoundingClientRect().top;
      // If element is close to page's bottom then window will scroll only to some position above the element.
      const targetY =
        document.body.scrollHeight - elementY < window.innerHeight
          ? document.body.scrollHeight - window.innerHeight
          : elementY;
      const diff = targetY - startingY;
      // Easing function: easeInOutCubic
      // From: https://gist.github.com/gre/1650294
      const easing = t => {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };
      let start;

      if (!diff) return;

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed miliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        let percent = Math.min(time / duration, 1);
        // Apply the easing.
        // It can cause bad-looking slow frames in browser performance tool, so be careful.
        percent = easing(percent);

        window.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    },
    /**
     * a function for handling api catches. the error.type handles in AxiosSetup.
     * @param {object} error error for handling api error. contain a type and data key.
     * @param {object} toastOptions object for toast configs.
     */
    apiCatch(error, toastOptions = {}) {
      if (error.isCancel) {
        return;
      }

      const getToastOptions = content => ({
        type: "error",
        title: "Error",
        content,
        closeTimeout: 5 * 1000,
        ...toastOptions,
      });

      switch (error.type) {
        case "Array":
          // sample structure:
          // these errors usually come from jsonApi errors and occur in DSR right now.
          /**
           * error: {
           *   type: "Array",
           *   data: [{
           *    detail: "Couldn't find this record",
           *    title: "Record not found"
           *   }],
           * }
           */
          for (let i = 0; i < error.data.length; i++) {
            const element = error.data[i];
            this.$dgToast.show(getToastOptions(element.title));
          }
          break;

        case "String":
          // sample structure:
          /**
           * error: {
           *  type: "String",
           *  data: "Please check your network connection",
           * }
           */
          this.$dgToast.show(getToastOptions(error.data));
          break;

        case "Other":
          // sample structure:
          /**
           * error: {
           *  type: "Other",
           *  data: {
           *    domain: ["is not a domain"]
           *  },
           *  status: 422,
           *  message: "Request failed with status code 422"
           * }
           */
          if (error.status === 422) {
            const errorArrays = Object.keys(error.data);
            for (let i = 0; i < errorArrays.length; i++) {
              const element = errorArrays[i];
              this.$dgToast.show(getToastOptions(`${element}: ${error.data[element][0]}`));
            }
          } else {
            this.$dgToast.show(getToastOptions(error.message));
          }
          break;

        default:
          this.$dgToast.show("Error occurred during your request.");
          break;
      }
    },
    fetchAppEnv() {
      const href = window.location.href;
      if (href.includes("int-ui-v3.")) return "integration";
      if (href.includes("stg-ui-v3.")) return "staging";
      if (href.includes("host.docker.internal") || href.includes("localhost")) return "localhost";
      return "production";
    },
    getUrl(world) {
      const appEnv = this.fetchAppEnv();
      if (world === "blue") {
        if (appEnv !== "production") return "https://staging.dataguard.de/";
        else return "https://app.dataguard.de/";
      }

      switch (appEnv) {
        case "localhost":
          return `http://${window.location.hostname}:3000/`;
        case "integration":
          return "https://int-my.dataguard.de/";
        case "staging":
          return "https://stg-my.dataguard.de/";
        default:
          return "https://my.dataguard.de/";
      }
    },
    blueDownloadAttachmentLink(documentId) {
      return `${this.getUrl("blue")}api/v1/downloads/${documentId}?type=attachments`;
    },
    capitalizeFirstLetter(str) {
      str = str.replace(/[_-\s]/g, " ");
      return str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    },
    stripHtml(html) {
      let temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return temporalDivElement.textContent || temporalDivElement.innerText || "";
    },
    copyElementContents(eleRef) {
      const range = document.createRange();
      range.selectNode(eleRef);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
    getFormattedDate(d) {
      var timestamp = new Date(d);
      var dd = String(timestamp.getDate()).padStart(2, "0");
      var mm = String(timestamp.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = timestamp.getFullYear();

      timestamp = `${dd}.${mm}.${yyyy}`;
      return timestamp;
    },
    filterPublishedNews(news) {
      return news.currentState.toLowerCase() === "published";
    },
    formatDate(date, pattern) {
      return format(date, pattern);
    },
    markdown(text) {
      const md = new MarkdownIt();
      return md.render(text);
    },
    // A function to calculate relative days/month/years towards a specific date (without time)
    relativeTime(datetime) {
      // Prepare units in seconds to calculate correct unit
      const UNITS = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: (24 * 60 * 60 * 1000 * 365) / 12,
        day: 24 * 60 * 60 * 1000,
      };

      const rtf = new Intl.RelativeTimeFormat(this.$i18n.locale, { numeric: "auto" });

      // Calulates time between in seconds
      const elapsed = new Date(datetime).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0);
      for (let u in UNITS) {
        // in case elapsed is zero it can't be used as denominator
        if (elapsed == 0) return rtf.format(0, "day");
        if (Math.abs(elapsed) >= UNITS[u]) return rtf.format(Math.round(elapsed / UNITS[u]), u);
      }
    },
  },
  created() {},
  beforeDestroy() {},
};
