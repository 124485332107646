<template>
  <div class="bottom-bar">
    <div class="platform-links">
      <a class="platform-links__item" v-for="(item, index) in items" :href="item.link" :key="index" target="_blank">
        {{ item.title }}
      </a>
    </div>
    <div v-if="isFeedbackEnabled">
      <a class="platform-links__item" :href="feedbackURL" target="_blank">
        {{ $t("bottom_bar.give_feedback") }}
      </a>
    </div>
  </div>
</template>

<script>
import { featureLinkMixin } from "@/engines/common/mixins";

export default {
  mixins: [featureLinkMixin],
  name: "BottomBar",
  data() {
    return {
      locale: this.$router.currentRoute.params.lang || this.defaultLocale,
      items: [
        {
          title: this.$t("bottom_bar.privacy_policy.label"),
          link: this.$t("bottom_bar.privacy_policy.link"),
        },
        {
          title: this.$t("bottom_bar.legal_notice.label"),
          link: this.$t("bottom_bar.legal_notice.link"),
        },
      ],
    };
  },
  computed: {
    isFeedbackEnabled() {
      return this.isFeatureEnabled("feedback");
    },
    feedbackURL() {
      return this.featureURL("feedback");
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $white;
  padding: 20px;
  border-top: 1px $light solid;
  z-index: 1;
}

.platform-links {
  &__item {
    color: $dark;
    font-size: 12px;

    & + & {
      margin-left: 16px;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }
}
</style>
