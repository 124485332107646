<template>
  <div v-bind:class="['dg-chat-question', !active ? 'inactive-question' : '']" @click="onClick">
    <div class="consultant-avatar" v-if="consultantAvatar.length > 0">
      <img :src="consultantAvatar" alt="Consultant" />
    </div>
    <div class="question-wrap">
      <header>
        <div class="order">{{ order }}</div>
        <h3>{{ label }}</h3>
        <DgInfoBox :label="helpLabel" :message="helpMessage" />
      </header>
      <transition name="question-fade">
        <div v-if="active" class="question-content"><slot></slot></div>
      </transition>
    </div>
  </div>
</template>

<script>
// assets
import "./ChatQuestion.scss";
import { DgInfoBox } from "../../basic";

export default {
  name: "DgChatQuestion",
  components: {
    DgInfoBox,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: "",
    },
    helpLabel: {
      type: String,
      default: "",
    },
    helpMessage: {
      type: String,
      default: "",
    },
    consultantAvatar: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
