<template>
  <form class="dg-form" @submit="preventForm">
    <component
      v-for="(field, index) in fields"
      :is="field.type"
      :formData="formattedData"
      :condition="field.condition"
      :key="index"
      :label="field.label"
      :name="field.name"
      :placeholder="field.placeholder"
      :initialValue="field.value"
      :errorMessage="field.errorMessage"
      :required="field.required"
      :options="field.options"
      :multiple="field.multiple"
      :searchable="field.searchable"
      @change="onChange(index, $event)"
    />
    <slot></slot>
    <DgButton :disabled="!userCanSubmit" @click="onSubmit">{{ submitLabel }}</DgButton>
  </form>
</template>

<script>
import { DgButton } from "../../basic/";
import { DgInput, DgEmail, DgPhone, DgSelect } from "../";

// assets
import "./Form.scss";

export default {
  name: "dg-form",
  components: { DgButton, DgInput, DgEmail, DgPhone, DgSelect },
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    submitLabel: {
      type: String,
      default: "Sparen",
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    const formData = [];

    let userCanSubmit = true;
    this.fields.forEach((field, index) => {
      if (field.value && field.value !== "") {
        formData[index] = field.value;
      }
      if (field.required && (!field.value || field.value === "")) {
        userCanSubmit = false;
      }
    });
    const formattedData = this.fields.map((field, index) => {
      return { ...field, value: formData[index] };
    });

    const submittedData = this.data;
    this.fields.map((field, index) => {
      submittedData[field.name] = formData[index];
    });

    return {
      formData,
      userCanSubmit,
      formattedData,
      submittedData,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.submittedData, this.formattedData);
    },
    onChange(index, event) {
      this.formData[index] = event;
      let userCanSubmit = true;

      this.fields.forEach((field, index) => {
        if (field.required && (!(index in this.formData) || this.formData[index] === "")) {
          userCanSubmit = false;
        }
      });
      this.userCanSubmit = userCanSubmit;

      this.formattedData = this.fields.map((field, index) => {
        return { ...field, value: this.formData[index] };
      });
    },
    preventForm(e) {
      e.preventDefault();
    },
  },
};
</script>
