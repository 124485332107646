<template>
  <p @click="onClick" :class="`dg-paragraph ${color}`">
    <slot></slot>
  </p>
</template>

<script>
// assets
import "./Paragraph.scss";

export default {
  name: "dg-p",
  props: {
    link: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
