<template>
  <div v-if="isAuthPath">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
  <div v-else>
    <div :class="['app', { 'app--is-loading': fetchingAllData }]">
      <DgSpinner v-if="fetchingAllData" />
      <template v-else>
        <div id="main-wrapper">
          <template v-if="userData && companyData">
            <DgNavHeader
              appName="Privacy"
              :logout="{
                title: $t('nav_header.logout'),
              }"
              @onLogout="logout"
              @langClick="changeLang"
              @onOptionClick="onOptionTopNavClick"
              :homeLink="homeLink"
              :enabledLangs="enabledLangs"
              :dropdownOptions="dropdownOptions"
              :user="user"
              :picHomeLink="picHomeLink"
              :menuLinks="menuLinks"
              :moreText="$t('nav_header.moreText')"
              data-testId="dg-nav-header"
              v-if="!isNoAccessPath"
            />
          </template>
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
          <DgOverlay
            extraClass="responsive-sidebar-menu-overlay"
            @click="toggleSidebarMenu"
            :visible="sidebarMenuVisibility"
          />
          <DgToast />
        </div>
        <dg-notification />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isActionCableConnected: false,
    };
  },
  computed: {
    ...mapState({
      sidebarNavigation: state => state.menu.sidebarNavigation,
      sidebarMenuVisibility: state => state.menu.sidebarMenuVisibility,
      userCompanies: state => state.user.userCompanies,
      currentCompany: state => state.user.currentCompany,
      companyData: state => state.user.companyData,
      userData: state => state.user.userData,
      fetchingAllData: state => state.user.fetchingAllData,
    }),
    ...mapGetters(["serverConfig"]),
    user() {
      return {
        name: this.userData.name,
        image: this.userData.image,
        title: this.companyData.name,
      };
    },
    homeLink() {
      return {
        attrs: {
          ...this.homeUrl.attrs,
          "data-testId": "dataGuard-logo",
        },
      };
    },
    picHomeLink() {
      const { lang: locale } = this.$route.params;
      return `${this.serverConfig.picHome}/${locale}?companyId=${this.companyData.app_id}`;
    },
    dropdownOptions() {
      const { lang: locale, id } = this.$route.params;
      let reset_link = [];
      let extraLink = [];
      let companyLink = [];
      let basic_info = [];
      let profile = [];
      this.companyData.reset_link.forEach(item => {
        reset_link.push({
          title: item.title,
          attrs: { data: item },
        });
      });
      this.companyData.extra_links.forEach(item => {
        extraLink.push({
          title: item.title,
          attrs: { href: `/redirect${item.link}` },
        });
      });
      this.userData.customers.forEach(({ id, name, ciso = false, app_id }) => {
        const attrs = { href: `${this.serverConfig.privacy}/${locale}/dashboard?platformId=${app_id}` };
        if (ciso) {
          attrs.href = `${this.serverConfig.infoSec}/${locale}`;
        }
        companyLink.push({
          title: name,
          active: this.$route.params.id === id,
          attrs,
        });
      });

      if (this.basicInfoTitle) {
        basic_info.push({
          key: "basic_info",
          options: [
            {
              title: this.basicInfoTitle,
              attrs: {},
            },
          ],
        });
      }

      if (!this.companyData.academy_access) {
        profile.push({
          key: "profile",
          options: [
            {
              title: this.$t("nav_header.profile"),
              attrs: {
                to: `/${locale}/c/${id}/profile`,
              },
            },
          ],
        });
      }

      let result = [
        ...profile,
        ...basic_info,
        {
          key: "resetlink",
          options: [...reset_link],
        },
        {
          key: "extraLink",
          options: [...extraLink],
        },
        {
          key: "company",
          title: "Company",
          options: [...companyLink],
        },
      ];
      return result;
    },
    menuLinks() {
      return this.companyData.navigation_link.map(item => {
        const target = item.type === "external" && !(item.link || "").match(/\.dataguard\.de/) ? "_blank" : "_self";
        const isExternal = ["external", "ruby"].includes(item.type);
        let linkProp = isExternal ? { href: item.link } : { to: item.link };
        return {
          name: item.title,
          attrs: { ...linkProp, target, "data-testId": `dg-top-navbar-${item.key}` },
        };
      });
    },
    homeUrl() {
      return this.menuLinks.find(item => item.name === "Home");
    },
    enabledLangs() {
      const locale = this.$route.params.lang;
      return [
        {
          locale: "de",
          url: "https://dataguard.azureedge.net/website/images/langs/de.svg",
          active: locale === "de",
        },
        {
          locale: "en",
          url: "https://dataguard.azureedge.net/website/images/langs/en.svg",
          active: locale === "en",
        },
      ];
    },
    isSidebarActive() {
      return Boolean(this.$route.meta.sidebar);
    },
    basicInfoTitle() {
      return this.companyData.is_current_user_main_contact ? this.$t("nav_header.basic_info") : null;
    },
    isAuthPath() {
      return this.$route.name === "Login" || this.$route.name === "Logout";
    },
    isNoAccessPath() {
      return this.$route.name === "no-access";
    },
    activeLang() {
      return this.enabledLangs.find(lang => window.location.pathname.startsWith(`/${lang.locale}/`));
    },
  },
  methods: {
    ...mapActions({
      toggleSidebarMenu: "toggleSidebarMenu",
    }),
    onOptionTopNavClick(data) {
      if (data.key === "resetlink") {
        this.onResetUser(data.data.attrs.data);
      } else if (data.key === "basic_info") {
        this.redirectToBasicInfo();
      }
    },
    changeLang(data) {
      const newLocale = data.locale;
      const currentLocale = this.activeLang.locale;
      if (newLocale !== currentLocale) {
        const fullPath = window.location.pathname;
        window.location.href = fullPath.replace(currentLocale, newLocale);
      }
    },
    onResetUser(resetUser) {
      const { title, confirm, commit, cancel } = resetUser.data;
      const onAccept = async () => {
        // Make a DELETE request to Red World for reset test customer
        const [, endpoint] = resetUser.link.split("/api/");
        try {
          await this.$http({
            url: `/api1/${endpoint}`,
            method: "DELETE",
          });
          /**
           * The above API call resets audit data
           * The reload will help refetch all data
           */
          window.location.reload();
        } catch (error) {
          this.$dgToast.show({
            type: "info",
            title: error,
            content: "Error submiting data, please try again.",
          });
        }
      };
      const params = {
        type: "confirm",
        title,
        content: confirm,
        confirmButtonLabel: commit,
        rejectButtonLabel: cancel,
        onAccept,
      };
      // show a dialog to ask user to confirm reset test customer
      this.$dgToast.show(params);
    },
    logout() {
      window.location.replace("/logout");
    },
    async redirectToBasicInfo() {
      window.location.href = "/redirect?app=blue&redirect=false";
    },
  },
  watch: {
    "$route.params.lang"(lang) {
      if (lang && !this.isActionCableConnected) {
        this.isActionCableConnected = true;

        this.$cable.connection.connect(`${this.getUrl()}cable?locale=${lang}`);
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta.translationKey) {
          document.title = this.$t(`document_titles.${to.meta.translationKey}`);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.app {
  min-width: 1140px;
  &--is-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dg-notification img {
    max-width: 100%;
  }
}
</style>
