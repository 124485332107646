export default {
  loading(state) {
    return state.isLoading;
  },
  getMappingData(state) {
    return state.mappingData;
  },
  getChangedState(state) {
    return state.mappingData.isChanged;
  },
  organisationData(state) {
    const mappingData = state.mappingData;
    return (entityKey, filterKey) => {
      switch (filterKey) {
        case "parts":
          return mappingData.organisationParts[entityKey];
        case "types":
          return mappingData.organisationTypes.find(organisationType => organisationType.name === entityKey);
        case "part_types":
          return mappingData.organisationPartTypes.find(
            organisationPartType => organisationPartType.name === entityKey
          );
        default:
          return mappingData.organisations[entityKey];
      }
    };
  },
  selectedOrgData(state) {
    const senders = state.mappingData.selectedSenders;
    const receivers = state.mappingData.selectedReceivers;
    const ds = state.mappingData.selectedDataSubjects;
    return (entityKey, filterKey) => {
      switch (entityKey) {
        case "senders":
          return filterKey
            ? senders.filter(val => val.entity_type == filterKey)
            : senders.filter(val => val.entity_type == "external");
        case "receivers":
          return filterKey
            ? receivers.filter(val => val.entity_type == filterKey)
            : receivers.filter(val => val.entity_type == "external");
        case "data_subjects":
          return filterKey
            ? ds.filter(val => val.entity_type == filterKey)
            : ds.filter(val => val.entity_type == "external");
        default:
          break;
      }
    };
  },
  getDataExchangesForEntity(state) {
    return entityKeyName => {
      return state.mappingData.dataExchanges[entityKeyName];
    };
  },
};
