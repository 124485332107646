<template>
  <transition
    name="accordion"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:before-leave="beforeLeave"
    v-on:leave="leave"
  >
    <div class="dg-accordion-content" v-show="showContent">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "dg-accordion-content",
  computed: {
    showContent() {
      return this.$parent.$attrs.id === this.$parent.$parent.isActive;
    },
  },
  methods: {
    beforeEnter: function(el) {
      el.style.height = "0";
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function(el) {
      el.style.height = "0";
    },
  },
};
</script>
