import Common from "./common";
import Dsr from "./DSR";
import Mcp from "./MCP";
import Dd from "./DD";
import Cpe from "./CPE";
import PPM from "./PPM";
import CxDashboard from "./CXDashboard";
import NewsPortal from "./NewsPortal";
import DGSuite from "./DGSuite";
import ToDo from "./ToDo";
import Profile from "./Profile";
import { Date } from "core-js";
export { Common, Dsr, Mcp, Dd, Cpe, PPM, CxDashboard, NewsPortal, DGSuite, ToDo, Profile };

// Global Helper Functions
window.formatDate = (dat, type) => {
  dat = new Date(dat);
  let month = dat.getMonth();
  let year = dat.getFullYear();
  let day = dat.getDate();
  month = month < 9 ? "0" + (month + 1) : month + 1;
  day = day < 9 ? "0" + day : day;
  if (type === "datetime") {
    return `${day}.${month}.${year} ${dat.getHours()}:${(dat.getMinutes() < 10 ? "0" : "") + dat.getMinutes()}`;
  }
  return `${day}.${month}.${year}`;
};

window.calculateDayDiff = (from, to) => {
  from = new Date(from);
  to = new Date(to);
  return (to - from) / 1000 / 60 / 60 / 24;
};
