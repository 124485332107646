<template>
  <div v-if="showModal" :class="['dg-modalcustom', `dg-modal--${variant}`, getButtonAlignment]">
    <div class="dg-modal__overlay"></div>
    <transition name="slide-fade" appear>
      <div v-if="showModal" :class="['dg-modal', { 'dg-modal--confirm': confirmation }]">
        <header>{{ header }}</header>
        <div v-if="!confirmLoading" v-on:click="closeModal" class="dg-modal__close"></div>
        <div class="dg-modal__container">
          <slot></slot>
          <div class="dg-modal__confirmation" v-if="noDefaultSlot && confirmation">
            <h2 class="dg-modal__title">{{ confirmation.title }}</h2>
            <p class="dg-modal__description">{{ confirmation.description }}</p>
          </div>
          <div class="dg-modal__confirm-btns" v-if="confirmation">
            <DgButton
              :disabled="confirmLoading"
              class="dg-modal__cancel"
              v-if="confirmation.cancel"
              :plain="true"
              type="outline"
              @click="cancelClick"
            >
              <span>{{ confirmation.cancel }}</span>
            </DgButton>
            <DgButton
              :loading="confirmLoading"
              class="dg-modal__confirm"
              v-if="confirmation.confirm"
              type="primary"
              @click="confirmClick"
            >
              <span>{{ confirmation.confirm }}</span>
            </DgButton>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DgButton from "../../basic/Button/Button";
export default {
  name: "dg-ModalCustom",
  components: {
    DgButton,
  },
  props: {
    header: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    confirmation: {
      type: Object,
      default: function() {
        return;
      },
    },
    btnsAlign: {
      type: String,
      default: "",
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noDefaultSlot() {
      return !this.$slots.default;
    },
    getButtonAlignment() {
      return `btns-align-${this.btnsAlign}`;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    confirmClick() {
      this.$emit("confirmClick");
    },
    cancelClick() {
      this.$emit("cancelClick");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./ModalCustom.scss";
</style>
