export default {
  SET_CUSTOMER_ACTIVITY(state, customerActivity) {
    state.customerActivity = customerActivity;
  },

  SET_AUDIT_AREA(state, auditArea) {
    state.auditArea = auditArea;
  },

  SET_COMPANY_NO(state, companyNo) {
    state.companyNo = companyNo;
  },

  SET_USER_DOCS(state, data) {
    state.uploadedDocs = data;
  },

  SET_UPLOADS_LOADING(state, data) {
    state.uploadsLoading = data;
  },
};
