<template>
  <div
    :class="[
      `dg-overlay ${extraClass}`,
      {
        absolute: position === 'absolute',
        active: visible,
      },
    ]"
    @click="handleOnClick"
  ></div>
</template>

<script>
// assets
import "./Overlay.scss";

export default {
  name: "DgOverlay",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    position: {
      type: String,
      default: "fixed",
    },
    extraClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleOnClick() {
      this.$emit("click");
    },
  },
};
</script>
