<template>
  <ul class="dg-top-navbar">
    <li
      class="dg-top-navbar__item"
      v-for="(item, index) in navItems"
      :key="index"
      :data-testId="'dg-top-navbar-' + item.key"
    >
      <a :target="setTarget(item.type)" class="dg-top-navbar__link" :href="item.link" v-if="item.external">
        {{ item.title }}
      </a>
      <router-link
        :class="['dg-top-navbar__link', 'dg-top-navbar__vue_link', { 'dg-top-navbar__home_link': item.key === 'home' }]"
        v-else
        :to="item.link"
      >
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
// styles
import "./TopNavbar.scss";

export default {
  name: "DgTopNavbar",
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    setTarget(type) {
      return type === "external" ? "_blank" : "_self";
    },
  },
};
</script>
