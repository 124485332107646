export default [
  {
    path: "c/:id/mcp",
    redirect: "c/:id/mcp/industries",
    component: () => import(/* webpackChunkName: "mcp" */ "./Layout.vue"),
    children: [
      {
        name: "MCPIndustries",
        path: "industries",
        component: () => import(/* webpackChunkName: "mcp" */ "./views/Industries.vue"),
        meta: {
          title: "Mutant Core process",
        },
      },
      {
        name: "MCPStepTwo",
        path: "details",
        component: () => import(/* webpackChunkName: "mcp-details" */ "./views/StepTwo.vue"),
        meta: {
          title: "Mutant Core process Details",
        },
      },
    ],
  },
];
