var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[`dg-form-editor dg-input`]},[(_vm.label)?_c('label',{attrs:{"for":_vm._name}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('TinymceEditor',{attrs:{"api-key":"blaosq7y2mbt13ds2zmi3z5wvtoxko7zsfs741x4p69nxwem","init":{
      height: 300,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help',
    }},on:{"onChange":_vm.onChange,"onBlur":_vm.onBlur},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(_vm.onError)?_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }