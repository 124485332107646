<template>
  <div class="dg-tab" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
import "./Tabs.scss";

export default {
  name: "DgTab",
  props: {
    name: { required: true },
    selected: { default: false },
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    this.isActive = this.selected;
  },
};
</script>
