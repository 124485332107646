<template>
  <div class="dg-mcp-modal">
    <div class="mcp-modal-wrapper">
      <div class="mcp-modal-header">
        <h4 v-html="header"></h4>
      </div>
      <div class="mcp-modal-body dg-scrollbar">
        <ul class="modal-audit-list">
          <li v-for="(audit_process, idx) in list" :key="idx" class="modal-audit">
            <div class="modal-audit-parent">
              <span class="audit-name">{{ idx + 1 }} {{ audit_process.name }}</span>
              <span class="industry-name">{{ audit_process.industry }}</span>
            </div>
            <ul class="modal-question-list">
              <li v-for="(question_group, idy) in audit_process.question_groups" :key="idy" class="modal-question">
                <span class="modal-question-name">{{ idx + 1 }}.{{ idy + 1 }} {{ question_group.name }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="mcp-modal-footer">
        <DgButtonGroup>
          <DgButton type="secondary" v-if="secondaryButton" :disabled="submiting" @click="$emit('onReject')">{{
            secondaryButton
          }}</DgButton>
          <DgButton type="primary" @click="$emit('onAccept')" :loading="submiting">{{ primaryButton }}</DgButton>
        </DgButtonGroup>
      </div>
      <div class="mcp-modal-close" @click="$emit('closeModal')" v-if="!submiting">
        <i class="dgi dgi-e-remove" />
      </div>
    </div>
  </div>
</template>

<script>
import { DgButton, DgButtonGroup } from "../../../basic/";
import "./Modal.scss";

export default {
  name: "DgMcpModal",
  props: {
    header: {
      type: String,
      required: true,
    },
    primaryButton: {
      type: String,
      required: true,
    },
    secondaryButton: {
      type: String,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
    submiting: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    DgButton,
    DgButtonGroup,
  },
};
</script>
