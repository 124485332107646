import Vue from "vue";
import { Permission, commentNode } from "@/services";

const analyseFeaturePermission = function (action = "view", featureCollection) {
  if (Array.isArray(featureCollection)) {
    return Permission.hasPermissions(action, featureCollection);
  } else {
    return Permission.isUserAllowed(action, featureCollection);
  }
};

Vue.prototype.$can = analyseFeaturePermission;

// Register a global custom directive called `v-can`
/**
 * You can use this directive on any element/component
 * For eg. <button v-can:add="'feature_name'">Create Entry</button>
 * For eg. <button v-can="'feature_name'">View Entry Details</button>
 * For eg. <button v-can:edit="'feature_name'">Edit Entry</button>
 * For eg. <button v-can:delete="'feature_name'">Delete Entry</button>
 *
 *
 * Also, you can use array of features for components
 * For eg. <button v-can:add="['feature_name', 'feature_name_2']">Create Entry</button>
 * For eg. <button v-can="['feature_name', 'feature_name_2']">View Entry Details</button>
 * For eg. <button v-can:edit="['feature_name', 'feature_name_2']">Edit Entry</button>
 * For eg. <button v-can:delete="['feature_name', 'feature_name_2']">Delete Entry</button>
 */
Vue.directive("can", {
  bind(el, binding, vnode) {
    /**
     * value will contain the feature name
     * arg will contain the action name view/add/edit/delete
     */
    const { value = "", arg = "view" } = binding;
    if (!analyseFeaturePermission(arg, value)) commentNode(el, vnode);
  },
});
