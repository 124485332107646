import store from "@/store";

const isUserAllowed = function (action = "view", featureName) {
  const allowedActions = ["view", "add", "edit", "delete"];
  if (!allowedActions.includes(action)) {
    throw new Error(`Action name is not allowed. Allowed values are "view", "add", "edit" and "delete"`);
  }
  const userPermissions = store.getters.userPermissions || {};
  if (Object.keys(userPermissions).length < 1) {
    // TODO: change this to false when Feature Flag enabled in FE project
    return true;
  }

  const featurePermission = userPermissions[featureName];
  if (!featurePermission) return false;

  let hasPermission = false;
  switch (action) {
    case "view":
      hasPermission = featurePermission.includes("read");
      break;
    case "add":
    case "edit":
    case "delete":
      hasPermission = featurePermission.includes("read_write");
      break;
    default:
      break;
  }
  return hasPermission;
};

const hasPermissions = function (action = "view", listOfFeatures) {
  if (Array.isArray(listOfFeatures)) {
    return listOfFeatures
      .map(item => {
        if (typeof item === "object" && item !== null) {
          return isUserAllowed(item.action, item.feature);
        }
        return isUserAllowed(action, item);
      })
      .includes(true);
  }

  return false;
};

export default {
  isUserAllowed,
  hasPermissions,
};
