<template>
  <div class="dg-dropdown">
    <div class="dg-dropdown__title" @click="toggleVisibility" v-html="title"></div>
    <ul ref="ul" class="dg-dropdown__container" v-if="isDropdownVisible">
      <li
        :class="{ active: selectedVal === val }"
        @click="clickedItem(val, $event)"
        v-for="(val, index) in vals"
        :key="index"
      >
        {{ val }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "DgDropDown",
  props: {
    title: {
      type: String,
      default: "Dropdown",
    },
    vals: {
      type: Array,
      default() {
        return [];
      },
    },
    triggerDropdown: {
      type: Boolean,
      default: false,
    },
    closeOnSelection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDropdownVisible: false,
      selectedVal: "",
    };
  },
  watch: {
    triggerDropdown(newVal) {
      this.newVal = this.isDropdownVisible;
    },
  },
  created() {
    window.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    toggleVisibility() {
      this.selectedVal = "";
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdown(e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdownVisible = false;
      }
    },
    clickedItem(val, e) {
      this.selectedVal = val;
      this.closeOnSelection ? this.toggleVisibility() : "";
      this.$emit("clickedItem", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-dropdown {
  position: relative;

  .dg-dropdown__title {
    cursor: pointer;
    padding: 8px 16px;
  }

  .dg-dropdown__container {
    position: absolute;
    z-index: 9999;
    right: 16px;
    top: 24px;
    background: #fff;
    padding: 0;
    list-style-type: none;
    box-shadow: 0px 12px 24px rgba(17, 42, 56, 0.1);
    border: 1px solid #e9edef;

    li {
      padding: 8px 16px;
      min-width: 137px;
      cursor: pointer;
      color: #003349;

      &.active {
        color: #cb333b;
      }

      &:hover {
        color: #cb333b;
      }
    }
  }
}
</style>
