export default [
  {
    name: "Login",
    path: "login",
    component: () => import(/* webpackChunkName: "login-component" */ "./views/Login.vue"),
  },
  {
    name: "Logout",
    path: "logout",
    component: () => import(/* webpackChunkName: "logout-component" */ "./views/Logout.vue"),
  },
  {
    name: "no-access",
    path: "no-access",
    component: () => import(/* webpackChunkName: "no-access-component" */ "./views/NoAccessView.vue"),
  },
];
