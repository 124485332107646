<template>
  <div v-if="showElement" :class="[`dg-form-input dg-input dg-input-${type}`, { errorInput: onError }]">
    <label v-if="label" :for="_name">
      {{ label }}
      <span class="required" v-if="required">*</span>
    </label>
    <template v-if="textarea">
      <textarea
        :id="_name"
        :name="_name"
        v-bind:value="value"
        class="dg-input"
        :class="{ error: onError }"
        :placeholder="placeholder"
        :required="required"
        @blur="onBlur"
        @input="onInput"
      ></textarea>
    </template>
    <template v-else>
      <input
        :type="type"
        :id="_name"
        :name="_name"
        v-bind:value="value"
        class="dg-input"
        :class="{ error: onError }"
        :placeholder="placeholder"
        :required="required"
        @blur="onBlur"
        @input="onInput"
      />
    </template>
    <p class="dg-input__hint" v-if="hintText">{{ hintText }}</p>
    <p v-if="onError" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
// assets
import "./Text.scss";

export default {
  name: "dg-input",
  props: {
    textarea: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    validator: {
      type: Function,
      default(input) {
        return !!input;
      },
    },
    formData: {
      type: Array,
      default() {
        return [];
      },
    },
    condition: {
      type: Object,
      default() {
        return {
          action: "none",
          target: "",
          condition: "",
          value: "",
        };
      },
    },
    hintText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      onError: false,
    };
  },
  methods: {
    onInput(event) {
      this.onError = false;
      this.$emit("input", event.target.value, event);
      this.$emit("change", event.target.value, event);
    },
    onBlur(event) {
      const inputValue = event.target.value;
      if (inputValue) {
        this.onError = this.validator ? !this.validator(inputValue) : false;
      } else if (this.required) {
        this.onError = true;
      }
      this.$emit("blur", inputValue, event);
    },
  },
  computed: {
    _name: function() {
      if (this.name !== "") {
        return this.name;
      } else {
        return this.label
          .toLowerCase()
          .replace(" ", "-")
          .replace("?", "-");
      }
    },
    showElement: function() {
      if (this.condition.action === "show" || this.condition.action === "hide") {
        const target = this.formData.filter(item => this.condition.target === item.name)[0];
        const targetValue = target && target.value ? target.value : null;

        let _showElement = true;
        if (targetValue) {
          _showElement = this.condition.value === targetValue;
        } else {
          _showElement = false;
        }

        if (this.condition.action === "show") {
          return _showElement;
        } else {
          return !_showElement;
        }
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-form-input.error {
  input {
    border-color: #c63040;
  }
}

.dg-input__hint {
  color: #718496;
  font-size: 12px;
  line-height: 16px;
  // margin-top: 6px;
}
</style>
