var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,{tag:"component",class:[
    `dg-button dg-button--${_vm.type} ${_vm.size || ''}`,
    {
      'no-hover': !_vm.hover || _vm.disabledState,
      'is-disabled': Boolean(_vm.disabledState),
      'is-loading': Boolean(_vm.loading),
      plain: _vm.plain,
      icon: _vm.icon,
      'only-text': Boolean(_vm.onlyText),
      'text-icon': Boolean(_vm.textIcon),
    },
  ],attrs:{"type":_vm.nativeType,"to":_vm.to,"href":_vm.href,"disabled":_vm.disabledState},on:{"click":_vm.onClick}},[(_vm.textIcon || _vm.icon)?_c('i',{class:`dgi ${_vm.finalIcon}`}):_vm._e(),(!_vm.icon || (!_vm.icon && _vm.textIcon))?_c('span',{staticClass:"dg-button__inner"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }