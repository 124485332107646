<template>
  <div class="dg-panel">
    <div class="dg-panel-header" v-if="header">
      <h4>{{ header }}</h4>
    </div>
    <div class="dg-panel-header" v-else-if="hasHeaderSlot">
      <slot name="header" />
    </div>
    <div class="dg-panel-body">
      <slot />
    </div>
  </div>
</template>

<script>
import "./Panel.scss";

export default {
  name: "dg-panel",
  props: {
    header: {
      type: String,
    },
  },
  computed: {
    hasHeaderSlot() {
      return this.$slots.header;
    },
  },
};
</script>
