export default [
  {
    path: "dpo/cpe/",
    component: () => import(/* webpackChunkName: "cpe-layout" */ "./Layout.vue"),
    children: [
      {
        name: "CPE_Home",
        path: "industry/:id/",
        component: () => import(/* webpackChunkName: "cpe-home" */ "./views/CPE_Home.vue"),
      },
    ],
  },
];
