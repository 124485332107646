import Vue from "vue";

// FIXME: We should have a service for HTTP calls.
export default {
  state: {
    products: [],
    groups: [],
    currentProduct: null,
    websites: {},
    currentWebsite: null,
  },
  actions: {
    async fetchGroups({ commit }) {
      const response = await Vue.prototype.$http.get(`/api1/v2/dg_suite_groups`);

      const { data: groups } = response.data;
      commit("setGroups", groups);
    },
    async fetchHighlightProducts({ commit }) {
      const endpoint = `/api1/v2/dg_suite_products/highlight`;
      const response = await Vue.prototype.$http.get(endpoint);

      const { data: products } = response.data;
      commit("setProducts", products);
    },
    async fetchCurrentProduct({ commit }, productId) {
      const endpoint = `/api1/v2/dg_suite_products/${productId}`;
      const response = await Vue.prototype.$http.get(endpoint);

      const { data: currentProduct } = response.data;

      commit("setCurrentProduct", currentProduct);
    },
    async fetchWebsites({ rootState, commit }) {
      const { id: customerId } = rootState.user.companyData;

      const response = await Vue.prototype.$http.get(`/api1/v2/customers/${customerId}/usercentrics/websites`);

      const { data: websites } = response.data;

      commit("setWebsites", websites);
    },
    async fetchWebsite({ rootState, commit }, websiteId) {
      const { id: customerId } = rootState.user.companyData;

      const response = await Vue.prototype.$http.get(
        `/api1/v2/customers/${customerId}/usercentrics/websites/${websiteId}`
      );

      const { data: website } = response.data;

      commit("setWebsite", website);
      commit("setCurrentWebsiteById", websiteId);
    },
    async saveWebsite({ rootState, commit }, websiteAttributes) {
      const { id: customerId } = rootState.user.companyData;

      let url = `/post-form?url=/api1/v2/customers/${customerId}/usercentrics/websites/`;
      let method = "post";

      if (websiteAttributes.id) {
        url = url.concat(websiteAttributes.id);
        method = "patch";
      }

      // We need to use FormData so our proxy middleware works properly for the logo upload as well as submitting an
      // array of permitted_user_emails.
      let formData = new FormData();
      Object.keys(websiteAttributes).forEach(key => {
        if (typeof websiteAttributes[key] === "object") {
          switch (key) {
            case "logo":
              formData.append(key, websiteAttributes[key]);
              break;
            case "permitted_user_emails":
              websiteAttributes[key].forEach(emailAddress => {
                formData.append("permitted_user_emails[]", emailAddress);
              });
              break;
            default:
              formData.append(key, JSON.stringify(websiteAttributes[key]));
          }
        } else {
          formData.append(key, websiteAttributes[key]);
        }
      });

      const response = await Vue.prototype.$http({
        url,
        method,
        data: formData,
      });

      const { data: website } = response.data;

      commit("setWebsite", website);
      return website.id;
    },
    async deleteWebsite({ rootState, commit }, websiteId) {
      const { id: customerId } = rootState.user.companyData;
      const url = `/api1/v2/customers/${customerId}/usercentrics/websites/${websiteId}`;

      await Vue.prototype.$http.delete(url);

      commit("removeWebsite", websiteId);
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setCurrentProduct(state, product) {
      state.currentProduct = product;
    },
    setWebsites(state, websites) {
      state.websites = {
        ...state.websites,
        ...websites.reduce((websites, website) => {
          websites[website.id] = website;
          return websites;
        }, {}),
      };
    },
    setWebsite(state, website) {
      state.websites = { ...state.websites, [website.id]: website };
    },
    setCurrentWebsiteById(state, websiteId) {
      state.currentWebsite = websiteId && state.websites[websiteId] ? state.websites[websiteId] : null;
    },
    removeWebsite(state, websiteId) {
      Vue.delete(state.websites, websiteId);
    },
  },
};
