/**
 * this function generate link based on navigation item is implemented on vuejs side or on ruby side.
 * @param {array} items array of navigation items
 * @param {string} customerId current customer id
 * @param {string} locale current locale of app
 */
const generateNavItems = (items, customerId, locale) => {
  if (!items || items.length === 0) {
    return [];
  }

  /**
   * splitted item to 3 separate parameter because to describe every item and it's probability values
   * and how they are important to handling link
   *
   * @param {string} key route name key
   * @param {string} type ruby | external | vue
   * @param {string | null} link url of item
   */
  const getRouteMeta = (key, type, link) => {
    switch (type) {
      case "external":
        return {
          link,
          external: true,
        };
      case "ruby":
        return {
          link: `/redirect/${link.replace("/", "")}`,
          external: true,
        };
      default:
        // we are on vue router from here
        const homeRoute = `/${locale}/c/${customerId}`;
        const appName = key === "home" ? "" : key;
        return {
          link: `${homeRoute}/${appName}`,
          external: false,
        };
    }
  };

  return items.map(item => {
    const { link, external } = getRouteMeta(item.key, item.type, item.link);

    return {
      ...item,
      link,
      external,
    };
  });
};

export default generateNavItems;
