<template>
  <component :is="tag" @click="onClick" :class="`dg-heading ${tag}`">
    <slot></slot>
  </component>
</template>

<script>
// assets
import "./Heading.scss";

export default {
  name: "dg-heading",
  props: {
    tag: {
      type: String,
      default: "h3",
    },
    link: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
