<template>
  <div class="dg-bottom-bar">
    <slot />
  </div>
</template>

<script>
import "./ActionBar.scss";

export default {
  name: "dg-bottom-bar",
};
</script>
