<template>
  <div class="dg-process-flow-container">
    <ul class="dg-process-flow">
      <li v-for="(processStep, index) in list" :key="index">
        <div class="process-number completed" v-if="processStep.completed">
          <img src="./images/checked.svg" />
        </div>
        <div v-else :class="['process-number', { current: processStep.title === currentStep.title }]">
          {{ index + 1 }}
        </div>
        <div class="dg-process-flow-text">{{ processStep.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import "./ProcessFlow.scss";

export default {
  name: "dg-process-flow",
  props: {
    list: {
      type: Array,
    },
    currentStep: {
      type: Object,
    },
  },
};
</script>
