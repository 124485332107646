<template>
  <div>
    <div class="dg-tabs">
      <ul class="dg-tabs-items">
        <li
          class="dg-tabs-item"
          @click="selectTab(tab)"
          v-for="(tab, id) in tabs"
          :key="id"
          :class="{ 'is-active': tab.isActive }"
        >
          <span class="dg-tabs-detail">{{ tab.name }}</span>
        </li>
      </ul>
    </div>
    <div class="dg-tabs-details">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import "./Tabs.scss";

export default {
  name: "DgTabs",
  data() {
    return { tabs: [] };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name == selectedTab.name;
      });
      this.$emit("onTabClick", selectedTab.name);
    },
  },
};
</script>
