import axios from "axios";

export default {
  state: {
    activeStep: 1,
    industries: [],
    chosenIndustries: [],
    basketIndustries: [],
    steps: [
      {
        id: 1,
        title: "Choose the industries",
      },
      {
        id: 2,
        title: "Choose the Processes to add to the Mutant",
      },
    ],
    customer: null,
  },
  getters: {
    getMcpCustomer(state) {
      return state.customer;
    },
    getMcpIndustries(state) {
      return state.industries;
    },
    getMcpchosenIndustries(state) {
      return state.chosenIndustries;
    },
    getMcpBasketIndustries(state) {
      return state.basketIndustries;
    },
    getMcpActiveStep(state) {
      return state.activeStep;
    },
    getMcpStep(state) {
      return value => {
        return state.steps.find(step => step.id === parseInt(value));
      };
    },
    getMcpIndustriesByQuery(state) {
      return value => {
        return JSON.parse(JSON.stringify(state.industries)).filter(mcp => {
          return mcp.name.toLowerCase().includes(value.toLowerCase());
        });
      };
    },
    getMcpChosenByQuery(state) {
      return value => {
        const results = [];
        const chosenIndustries = JSON.parse(JSON.stringify(state.chosenIndustries));

        chosenIndustries.forEach(industry => {
          let searchedIndustries = JSON.parse(JSON.stringify(industry));
          searchedIndustries.audit_processes = [];

          const searchedAuditProcesses = industry.audit_processes.map(auditProcess =>
            auditProcess.name.toLowerCase().includes(value.toLowerCase())
              ? { includes: true, ...auditProcess }
              : { includes: false, ...auditProcess }
          );

          searchedAuditProcesses.forEach(auditProcess => {
            if (auditProcess.includes) {
              searchedIndustries.audit_processes.push(auditProcess);
              if (results.indexOf(searchedIndustries) === -1) {
                results.push(searchedIndustries);
              }
            } else {
              let searchedQuestionGroups = JSON.parse(JSON.stringify(auditProcess.question_groups));
              auditProcess.question_groups = [];

              const questionGroups = searchedQuestionGroups.filter(questionGroup =>
                questionGroup.name.toLowerCase().includes(value.toLowerCase())
              );

              if (questionGroups.length > 0) {
                auditProcess.question_groups = questionGroups;
                searchedIndustries.audit_processes.push(auditProcess);

                if (results.indexOf(searchedIndustries) === -1) {
                  results.push(searchedIndustries);
                }
              }
            }
          });
        });

        return results;
      };
    },
  },
  actions: {
    setMcpActiveStep({ commit }, step) {
      commit("SET_MCP_ACTIVE_STEP", step);
    },
    addMcpchosenIndustry({ commit }, industry) {
      commit("ADD_MCP_chosen_INDUSTRY", industry);
    },
    removeMcpchosenIndustry({ commit }, industry) {
      commit("REMOVE_MCP_chosen_INDUSTRY", industry);
    },
  },
  mutations: {
    ADD_MCP_INDUSTRIES(state, industries) {
      state.industries = industries;
    },
    SET_MCP_ACTIVE_STEP(state, step) {
      state.activeStep = step;
    },
    ADD_MCP_chosen_INDUSTRY(state, industry) {
      state.chosenIndustries.push(industry);
    },
    ADD_MCP_BASKET_INDUSTRY(state, industry) {
      state.basketIndustries.push(industry);
    },
    REMOVE_MCP_chosen_INDUSTRY(state, industry) {
      const chosenIndustries = state.chosenIndustries;

      chosenIndustries.splice(
        chosenIndustries.findIndex(item => item.id === industry.id),
        1
      );
    },
    SET_MCP_CUSTOMER(state, customer) {
      state.customer = customer;
    },
  },
};
