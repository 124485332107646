<template>
  <!-- Leaving a comment here to check an update -->
  <div class="dg-modal-wrap" v-if="visible">
    <div class="dg-modal">
      <DgImage v-if="headerImage" :url="headerImage" alt="title" isRounded isBordered />
      <hr />
      <DgButton v-if="acceptButtonLabel" floatRight @click="onAccept">
        {{ acceptButtonLabel }}
      </DgButton>
      <DgButton v-if="rejectButtonLabel" floatLeft @click="onReject" type="plain">{{ rejectButtonLabel }}</DgButton>
    </div>
  </div>
</template>

<script>
import DgModal from "./index.js";
import { DgButton, DgImage } from "../../basic/";

// assets
import "./Modal.scss";

export default {
  name: "dg-modal",
  components: {
    DgButton,
    DgImage,
  },
  props: {
    headerImage: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      acceptButtonLabel: null,
      rejectButtonLabel: null,
    };
  },
  methods: {
    showModal(params) {
      this.acceptButtonLabel = params.acceptButtonLabel;
      this.rejectButtonLabel = params.rejectButtonLabel;
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },
    onAccept() {
      this.$emit("onAccept");
    },
    onReject() {
      this.$emit("onReject");
    },
  },
  beforeMount() {
    DgModal.EventBus.$on("show", params => {
      this.showModal(params);
    });
  },
};
</script>
